import { useRef } from 'react'

// Componets
import BaseButton from '../base/BaseButton/BaseButton'
import PropertyCard from '../Property/PropertyCard/PropertyCard';

// Icons
import { ReactComponent as arrowLeft } from 'feather-icons/dist/icons/chevron-left.svg';
import { ReactComponent as arrowRight } from 'feather-icons/dist/icons/chevron-right.svg';

// Translation
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

  /**
   * A component that renders a scrollable list of PropertyCards, with left and right
   * arrow buttons to scroll the list. The list is scrollable horizontally.
   * If there are no properties, it renders a message translating to "No properties".
   * @returns {JSX.Element}
   */
const PropertyCardSlider = ({isHome= false, properties }): JSX.Element => {
    const { t } = useTranslation();
    const scrollRef = useRef<HTMLDivElement>(null);
    const { propertyId, subPropertyId } = useParams<{ propertyId: string,  subPropertyId: string }>();

    // Filtra le proprietà se `subId` è presente, altrimenti usa tutte le proprietà
    const complexProperties =  propertyId && !subPropertyId ? 
    properties.filter((property) => +property.id !== +propertyId) 
    : subPropertyId ? properties.filter((property) => +property.id !== +subPropertyId) 
    : properties;

    const scrollLeft = () => {
        if (scrollRef.current) {
          scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' }); // Adjust the value as needed
        }
      };
  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' }); // Adjust the value as needed
    }
  };

  return (
    <div className={`relative flex flex-col md:flex-row items-center  pt-0 ${isHome?'mb-28 md:p-10': subPropertyId ?'mb-0 p-0':'md:p-10'} overflow-hidden w-full`}>
        <BaseButton 
            dataType='black-text' 
            className='hidden md:block absolute left-3 top-1/2 transform -translate-y-1/2'
            iconComponent={arrowLeft}
            onClick={scrollLeft} 
        />

        <div ref={scrollRef} className="flex flex-col md:flex-row items-center md:items-start overflow-x-auto w-full">
        {complexProperties?.length > 0 ? (
            complexProperties.map((property, index) => (
            <li className="list-none" key={index}>
                <PropertyCard property={property}/>
            </li>
            ))
        ) : (
            <p className="App-text">{t('no_property')}</p>
        )}
        </div>

        <BaseButton 
            dataType='black-text' 
            className='hidden md:block absolute right-3 top-1/2 transform -translate-y-1/2'
            iconComponent={arrowRight}
            onClick={scrollRight} 
        />
    </div>
  )
}

export default PropertyCardSlider;
