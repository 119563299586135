import React, { useEffect, useState } from 'react';
import './App.css';

// Routes
import Routes from './routes/Routes';

// Slider
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Translation
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('appLang');
    
    if (savedLanguage && i18n.isInitialized) {
      i18n.changeLanguage(savedLanguage);
      setIsInitialized(true);

    } else if (i18n.isInitialized) {
      setIsInitialized(true);
    }

    // Listener per aggiornare lo stato quando la lingua cambia
    i18n.on('languageChanged', () => setIsInitialized(true));
    
    // Cleanup listener on unmount
    return () => {
      i18n.off('languageChanged');
    };
  }, [i18n]);

  if (!isInitialized) {
    return <div>Loading...</div>; // Mostra un caricamento fino a quando non è pronto
  }

  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;