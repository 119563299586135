import React from 'react';

// Components
import BaseLayout from '../../base/BaseLayout/BaseLayout';
import PdfDocument from '../../PdfDocument/PdfDocument';

// Hooks
import { usePropertyDetails } from '../PropertyDetails/hook/property-details-hook';

// Store
import useStore from '../../../store/store';

// Translations
import { useTranslation } from 'react-i18next';

// Types
import { PropertyDescriptionProps } from '../types/types';

const PropertyDocuments: React.FC<PropertyDescriptionProps> = ({ details, id }) => {
  const { t } = useTranslation();
  const { downloadAllDocuments } = usePropertyDetails(String(id), undefined);

  // Extract document URLs if details and documenti_data_room are defined
  const documentsDataRoom = details?.documenti_data_room || [];
  const documentsDueDiligent = details?.documenti_due_diligence || [];

  const secondLevel = useStore(state => state.secondLevel);
  const allDocuments = [...documentsDataRoom, ...documentsDueDiligent];

  return (
    <BaseLayout title="documents" btn={secondLevel ? t('download') : undefined} btnFn={() => secondLevel && downloadAllDocuments(allDocuments)} subtextStyle='underline'>
      {documentsDataRoom.length > 0 && (
        <>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 py-4">
            <PdfDocument key={id} id={typeof id === 'number' ? id.toString() : id} documents={documentsDataRoom} />
          </div>
          {secondLevel && (
            <div className="grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 py-4">
              <PdfDocument key={id} id={typeof id === 'number' ? id.toString() : id} documents={documentsDueDiligent} />
            </div>
          )}
        </>
      )}
    </BaseLayout>
  );
};

export default PropertyDocuments;
