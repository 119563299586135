import React from 'react';

// Component
import BaseButton from '../BaseButton/BaseButton';

// Icon
import { ReactComponent as close } from 'feather-icons/dist/icons/x.svg'

interface BaseModalProps {
  children?: React.ReactNode;
  handleClose: () => void;
  isForm?: boolean;
  open: boolean;
  widthFix?: string;
}

const BaseModal: React.FC<BaseModalProps> = ({ children, open, handleClose, widthFix, isForm}) => {
  if (!open) return null;
  const modalSyles = widthFix ? widthFix : 'w-fit';

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50" >
      <div className={`${modalSyles} h-full md:h-fit md:max-h-[800px] relative bg-white shadow-lg p-4 md:rounded-xl z-[99] ${isForm ?'overflow-scroll md:overflow-visible': 'overflow-y-auto'}`}>
        <BaseButton className="m-8 md:m-0 absolute top-4 right-8 md:p-0" dataType='black-text' iconClass='w-7 md:w-[18px] h-auto' iconComponent={close} onClick={handleClose}/>
        {children}
      </div>
    </div>
  );
};

export default BaseModal;