import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Components
import Home from '../Views/Home/Home'; 
import PropertyDetails from '../components/Property/PropertyDetails/PropertyDetails';
import ProtectedRoute from './ProtectedRoutes';

// Views
import Login from '../Views/Login/Login';
import LandingPage from '../Views/LandingPage/LandingPage';

/**
 * Configuration for the application's routes using React Router.
 * It uses the `BrowserRouter` component from `react-router-dom` to wrap the application's routes, providing a consistent routing experience.
 */
const ConfigurationRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/home" element={<ProtectedRoute element={<Home />} />} />
        <Route path="/property/:propertyId" element={<ProtectedRoute element={<PropertyDetails />} />} />
        <Route path="/property/:propertyId/sub-property/:subPropertyId" element={<ProtectedRoute element={<PropertyDetails />} />} />
      </Routes>
    </Router>
  );
};

export default ConfigurationRoutes;
