// Store
import useStore from '../../../store/store';

// Translations
import { useTranslation } from 'react-i18next';

/**
 * A dynamic checkbox component that allows users to toggle a checkbox and displays a label.
 *
 * @param {object} props - The component props.
 * @param {string} props.label - The label to display next to the checkbox.
 * @returns {JSX.Element} The dynamic checkbox component.
 */
const DynamicCheckbox = ({ label }) => {

  const { t } = useTranslation();
  const { isChecked, setIsChecked }= useStore(state => state);

  /**
   * Toggles the checked state of the checkbox.
   */
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div className="flex items-center">
      <input
        type="checkbox"
        id="dynamicCheckbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        className=""
      />
      <label htmlFor="dynamicCheckbox" className="text-xs font-roboto text-[#3C3D3B] p-4">
        {t(label)} <span className='text-custom-red'>*</span>
      </label>
    </div>
  );
};

export default DynamicCheckbox;
