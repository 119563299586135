// Components
import BaseButton from '../base/BaseButton/BaseButton'

// Hooks
import useHeader from '../Property/PropertyDetails/hook/header-hook';
import useHome from '../../Views/Home/hook/home-hook';

// Translations
import { useTranslation } from 'react-i18next';

export default function FooterWidthButtons({isLanding, btnBText, btnRText, btnRedFn, btnBlackFn}) {

  const { t } = useTranslation();
  const { changeLanguage } = useHome();
    const { headerButtons, languageButtons, } = useHeader({ isLanding, btnBText, btnRText, btnRedFn, btnBlackFn });
  return (
    <div className='flex md:hidden flex-row items-center justify-between bg-custom-red fixed bottom-0 w-full p-6 z-20'>
      <div className='flex'>
        {languageButtons.map((btn, index) => (
          <BaseButton
            key={index}
            dataType={btn.dataType as 'default' | 'black' | 'black-underline' | 'red-text-line' | 'black-text' | 'pink-text' | 'pink-red' | 'white' | 'white-red' | 'text-red'| 'white-red-rounded' }
            onClick={() => changeLanguage(btn.lang)}
            text={t(btn.lang)}
            className='mr-2 w-9 justify-center flex'
          />
        ))}
      </div>
      <div className='flex'>
        {headerButtons.map((btn, index) => (
          <BaseButton 
            key={index} 
            onClick={btn.click} 
            dataType={btn.dataType as 'default' | 'black' | 'black-underline' |'red-text-line' | 'black-text' | 'pink-text' | 'pink-red' | 'white' | 'white-red' | 'text-red' | 'white-red-rounded'}
            text={btn.text} 
            className={btn.class} 
          />
        ))}
      </div>
    </div>
  )
}
