import React from 'react';

// Component
import BaseButton from '../base/BaseButton/BaseButton';
import ContactAgentSection from '../ContactAgentSection/ContactAgentSection';
import GenericForm from '../GenericForm/GenericForm';
import NextStepsList from '../NextStepList/NextStepList';

// Hook
import useFormField from '../../hooks/form-hook/form-hook';

// Translation
import { useTranslation } from 'react-i18next';

// Defines the props for the `SignatureContent` component.
interface SignatureContentProps {
  title?: string;
  subtitle: string;
  isLanding?: boolean;
  checkboxLabel?: string;
  submitText?: string;
  handleSubmit?: (event: React.FormEvent) => void;
  dataroom?: number;
  duediligence?: number;
}

/**
 * Renders the SignatureContent component, which displays information about the next steps in the home purchase process and provides a way to contact the real estate agent.
 *
 * @returns {JSX.Element} The rendered SignatureContent component.
 */
const SignatureContent: React.FC<SignatureContentProps> = ({
  title,
  subtitle,
  isLanding = false,
  checkboxLabel = '',
  submitText = '',
  handleSubmit,
  duediligence,
  dataroom,
}) => {
  const { t } = useTranslation();

  const fields  = useFormField();

  // Handles the click event on a button by opening the user's default email client with a pre-filled email address.
  const handleButtonClick = () => {
    window.location.href = 'mailto:example@example.com';
  }

  // Conditionally renders a BaseButton component based on the values of the `dataroom` and `duediligence` props.  If `dataroom` is 0, the button will have the text "request_details_room" and will call the `handleButtonClick` function when clicked. If `duediligence` is 0, the button will have the text "request_details_diligence" and will call the `handleButtonClick` function when clicked. The `newtitle` variable is also set based on the values of `dataroom` and `duediligence`.
  let buttonToShow = null;
  let newtitle= title ||'signature_purchase_property'

  if (dataroom === 0) {
    newtitle ='request_details_room'
    buttonToShow = <BaseButton text={t('request_details_room')} className='justify-center font-medium md:w-fit md:ml-auto' onClick={handleButtonClick} />;
  } else if (duediligence === 0) {
    newtitle ='request_details_diligence'
    buttonToShow = <BaseButton text={t('request_details_diligence')} className='justify-center font-medium md:w-fit md:ml-auto' onClick={handleButtonClick} />;
  }

  return (
    <div className='px-8 py-4 md:p-8 h-full md:h-fit flex flex-col justify-between'>
      <div>
      <h1 className='w-4/5 md:w-full font-playfair text-4xl mb-4'>{t(newtitle)}</h1>
      <h3 className={`font-roboto text-sm ${!isLanding ? 'uppercase font-medium' : 'mb-8'} pt-4`}>{t(subtitle)}</h3>
      {!isLanding ? (
        <NextStepsList stepKey="data_room_steps" />
      ) : (
        <GenericForm
          inputColor='text-black'
          inputErrorColor='text-custom-red'
          checkboxLabel={checkboxLabel}
          fields={fields}
          submit={submitText}
          onSubmit={handleSubmit}
        />
      )}

      </div>
     
      {(dataroom !==0 && duediligence !==0) &&!isLanding && <ContactAgentSection />}
      {buttonToShow}

    </div>
  );
};

export default SignatureContent;