import React from 'react';
import { useTranslation } from 'react-i18next';

interface BaseButtonProps {
  className?: string;
  dataType?: 'default' | 'black' | 'black-underline' | 'red-text-line' | 'black-text'| 'pink-text' | 'pink-red' | 'white'| 'white-red' |'text-red'| 'white-red-rounded'|'white-text-line'; 
  disabled?: boolean;
  iconClass?: string;
  iconComponent?: React.ElementType;
  onClick?: () => void;
  text?: string;
  type?: 'button' | 'submit' | 'reset';
}


const BaseButton: React.FC<BaseButtonProps> = ({
  className = '',
  dataType = 'default',
  disabled = false,
  iconClass,
  iconComponent,
  onClick,
  type = 'button',
  text,
}) => {
  const { t } = useTranslation();

  const styles: Record<BaseButtonProps['dataType'], { button: string; icon?: string; text?: string }> = {
    default: { button: 'border-none bg-custom-red text-white px-4', text: 'text-sm' },
    'black': { button: 'border-none bg-black text-white px-2', icon: 'w-[18px] h-auto', text: 'text-sm' },
    'black-text': { button: `border-none bg-transparent text-black ${iconComponent ? 'p-0':'px-px md:px-4'} mr-2 md:mr-0`, icon: 'w-[18px] h-auto', text: 'text-sm' },
    'black-underline': { button: 'border-none bg-transparent text-black underline', text: 'text-sm', icon:'w-[18px] h-auto'},
    'red-text-line':{ button: 'border border-custom-red bg-transparent text-custom-red px-2', icon: 'w-[18px] h-auto', text: 'text-sm' },
    'pink-text': { button: 'border-none bg-custom-red text-custom-pink font-medium px-px md:px-4 mr-2 md:mr-0', icon: 'stroke-custom-pink', text: 'text-[10px] md:text-sm' },
    'pink-red': { button: 'border-none bg-custom-pink px-2 text-custom-red', icon: '', text: 'text-sm' },
    'white': { button: 'border-none bg-white text-black px-2', icon: 'w-[18px] h-auto', text: 'text-sm' },
    'white-red-rounded': { button: 'border-none bg-white text-custom-red md:rounded-full px-2', icon: 'w-[18px] h-auto text-custom-red', text: 'text-sm' },
    'white-red': { button: 'border-none bg-white px-2 text-custom-red', icon: '', text: 'text-sm' },
    'white-text-line':{ button: 'border border-custom-white bg-transparent text-white px-2', icon: 'w-[18px] h-auto', text: 'text-sm' },
    'text-red':{ button: 'border-none bg-transparent text-custom-red px-2', icon: 'w-[18px] h-auto', text: 'text-sm' }
  }
  const { button, icon, text: textStyle } = styles[dataType];
  // Utility function to check if the class contains an h- class
  const hasHeightClass = (className: string) => {
    return /\bh-\d+\b/.test(className);
  };

  // Conditionally add h-8 if there is no other h-specific class
  const buttonClass = `${button} hover:${button.replace('bg-', 'hover:bg-').replace('text-', 'hover:text-')} ${className} flex items-center disabled:opacity-50 ${hasHeightClass(className) ? '' : 'h-8'} cursor-pointer`;

  const iconClassName = `${iconClass || icon} ${!text ? 'mr-0 md:mr-0' : 'mr-1 md:mr-2'}`;

  return (
    <button type={type} onClick={onClick} className={buttonClass} disabled={disabled}>
      {iconComponent && React.createElement(iconComponent, { className: `${iconClassName} p-px` })}
      {text && <p className={textStyle}>{t(text)}</p>}
    </button>
  );
};

export default BaseButton;