// Components
import DescriptionText from './hooks/PropertyDescriptionText/PropertyDescriptionText';
import TableMetrics from '../PropertyMetrics/TableMetrics/TableMetrics';

// Hook
import { useDescriptionExpansion } from './hooks/description-expans-hook'

// Translation
import { useTranslation } from 'react-i18next';

// Types
import { PropertyDescriptionProps } from '../types/types';

// Utils
import { createMetrics } from '../../../utils/metricUtils'; 

/**
 * Renders the property description component, which includes the property title, subtitle, description text, and property metrics.
 *
 * @param {Object} details - The property details object.
 * @param {string} title - The title of the property.
 * @param {string} subtitle - The subtitle of the property.
 * @returns {JSX.Element} - The rendered property description component.
 */
const PropertyDescription: React.FC<PropertyDescriptionProps> = ({ details, title, subtitle, onClick }) => {
  
  const { t } = useTranslation();
  const { isExpanded, toggleExpansion } = useDescriptionExpansion();
  const { metrics, mq } = createMetrics(details);
  
  // Filters metrics and mq arrays to only include items where number is not 0, but always include items with alwaysVisible = true
  const allMetrics = metrics.filter(metric => metric.number !== undefined && +metric.number !== 0);
  const allMq = mq.filter(metric => metric.number !== undefined && +metric.number !== 0);

  return (
    <>
      <section className="mx-8 mt-8">
        <div className='flex flex-col w-full'>
          <h1 className="text-5xl font-playfair uppercase font-medium">{title}</h1>
          <p className="my-4 text-4xl font-playfair font-medium uppercase">{subtitle}</p>
          <DescriptionText details={details} isExpanded={isExpanded} />
          <div className="md:hidden mt-2 inline">
            <button onClick={toggleExpansion} className="font-bold text-sm">
              {!isExpanded ? t('more') : t('less')}
            </button>
          </div>
        </div>
        <div className="flex flex-col md:flex-row w-full mt-8">
        <TableMetrics  className='md:pt-8 w-full md:w-1/2' title='into_numbers' metrics={allMetrics} showMoreFn={details.enabled_data_room === 0 ? onClick : null} />
        <TableMetrics className='w-full md:w-1/2' title='into_mq' metrics={allMq} unit='mq' />
      </div>
        
      </section>
      
    </>
  );
};

export default PropertyDescription;
