import { useState } from "react";

// Translations
import { useTranslation } from "react-i18next";

/**
 * Props for the FormattedText component
 */
interface FormattedTextProps {
    text: string;
  }

 /**
     * A React component that renders a formatted text block with the ability to expand or collapse the content.   
     * @param {object} props - The component props.
     * @param {string} props.text - The text to be displayed.
     * @returns {JSX.Element} - The formatted text component.
     */
 const FormattedText: React.FC<FormattedTextProps> = ({ text }) => {
    const { t } = useTranslation();
    const [isExpanded, setIsExpanded] = useState(false);
  
    //Splits the provided `text` string into an array of phrases, where each phrase is separated by a period followed by a space.
    const phrases = text.split(/(?<=\. )/);
  
    //Formats the provided phrases by adding a newline character after each phrase, and an extra newline after phrases that end with "evaluation." or "valutazione.".
    const formattedPhrases = phrases.map((phrase) => {
      const endsWithEvaluation = /(evaluation.|valutazione.)$/i.test(phrase.trim());
    
      if (endsWithEvaluation) {
        return phrase + '\n\n';
      } else {
        return phrase + '\n';
      }
    });
  
    //Joins the formatted phrases into a single string.
    const formattedText = formattedPhrases.join('');

    //Returns the formatted text component.
    return (
      <div>
        <div 
          className={`inline-block max-w-full mr-0 md:mr-9 whitespace-pre-wrap break-words overflow-hidden ${isExpanded ? 'max-h-full' : 'max-h-36 md:max-h-full'} text-sm`}
        >
          { formattedText }
        </div>
        <div className="md:hidden mt-2 inline">
          <button 
            onClick={() => setIsExpanded(!isExpanded)} 
            className="font-bold text-sm underline"
          >
            {!isExpanded ? t('more') : t('less')}
          </button>
        </div>
      </div>
    );
};
export default FormattedText;
