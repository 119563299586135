import { Metric } from "../components/Property/types/types";

/**
 * Creates an object containing metrics and measurement data for a property.
 * @returns {object} An object containing metrics and measurement data.
 */
export const createMetrics = (details) => {
  const metrics: Metric[] = [
    { number: details.num_vani_locali, name: 'local_rooms', alwaysVisible: true }, // Always visible
    { number: details.num_camere, name: 'rooms', alwaysVisible: true }, // Always visible
    { number: details.num_posti_letto, name: 'beds' },
    { number: details.num_bagni, name: 'baths' },
    { number: details.num_bagni_ensuite, name: 'suitbaths' },
    { number: details.estate_num_ville, name: 'villas' },
    { number: details.estate_num_spiagge, name: 'beaches' },
    { number: details.estate_num_piscine, name: 'pools', alwaysVisible: true }, // Always visible
    { number: details.estate_num_boe, name: 'buoys' },
    { number: details.estate_num_attracchi, name: 'moorings' }, 
    { number: details.num_posti_auto, name: 'parking_spaces' },
    { number: details.num_box, name: 'garage' },
    { number: details.num_corpi, name: 'buildings' },   
  ];

  const mq: Metric[] = [
    { number: details.mq_interni, name: 'square_meters.internal' },
    { number: details.mq_commerciali, name: 'square_meters.commercial' },
    { number: details.mq_terreno, name: 'square_meters.land' },
    { number: details.mq_giardino, name: 'square_meters.garden', alwaysVisible: true }, // Always visible
    { number: details.mq_terrazze, name: 'square_meters.terrace' },
    { number: details.mq_veranda_coperta, name: 'square_meters.covered_porch' },
    { number: details.mq_veranda_esterna, name: 'square_meters.external_porch' },
    { number: details.mq_balconi, name: 'square_meters.balconies' },
    { number: details.mq_edificabili, name: 'square_meters.buildable' }, 
    { number: details.m3_edificabili, name: 'square_meters.m3' },    
    { number: details.indice_costruttibilità, name: 'square_meters.construction_index' },
  ];

  return { metrics, mq };
};
