import axios, { AxiosInstance, AxiosError } from 'axios';
// import cookie from 'js-cookie';

const apiUrl =  process.env.REACT_APP_API_URL 

const instance: AxiosInstance = axios.create({
  baseURL: `${apiUrl}/public/api/`
});

// Imposta l'intestazione di autorizzazione da cookie prima di ogni chiamata API
instance.interceptors.request.use(
  (config) => {
    // const token = cookie.get('token');
    // if (token) {
    //   config.headers.Authorization = `Bearer ${token}`;
    // }
    return config;
  },
  (error) => {
    // Gestione degli errori durante l'impostazione del bearer
    console.error(error);
    throw error;
  }
);

instance.interceptors.response.use(
  (response) => {
    return response.data.data;
  },
  (error: AxiosError) => {
    // Gestione degli errori della risposta
    const { message, code } = error;
    return {
      statusCode: code,
      msg: message,
    };
  }
);

export default instance;
