/**
 * A custom React hook that provides functionality to display a full-screen image or video.
 * @returns {Object} An object with a `handleMediaClick` function that can be used to display a full-screen media element.
 */
const useFullScrenImage = () => {
    const handleMediaClick = (mediaType, mediaSrc) => {
     
        const mediaElement = document.createElement(mediaType);
        mediaElement.src = mediaSrc;

        if (mediaType === 'video') {
            mediaElement.controls = true;
            mediaElement.autoplay = true;
        }

        mediaElement.style.position = 'fixed';
        mediaElement.style.top = '0';
        mediaElement.style.left = '0';
        mediaElement.style.width = '100%';
        mediaElement.style.height = '100%';
        mediaElement.style.zIndex = '9999';
        mediaElement.style.backgroundColor = 'black';

        document.body.appendChild(mediaElement);

        if (mediaElement.requestFullscreen) {
            mediaElement.requestFullscreen();
        } else if (mediaElement.mozRequestFullScreen) { /* Firefox */
            mediaElement.mozRequestFullScreen();
        } else if (mediaElement.webkitRequestFullscreen) { /* Chrome, Safari, and Opera */
            mediaElement.webkitRequestFullscreen();
        } else if (mediaElement.msRequestFullscreen) { /* IE/Edge */
            mediaElement.msRequestFullscreen();
        }

        mediaElement.onfullscreenchange = () => {
            if (!document.fullscreenElement) {
                mediaElement.remove();
            }
        };
    };

    return { handleMediaClick };
}

export default useFullScrenImage;