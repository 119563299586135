import React from 'react';

// Components
import BaseButton from '../../base/BaseButton/BaseButton';
import IconWithBorder from '../../IconWithBorder/IconWithBorder';

// Images
import { ReactComponent as LogoIcon } from '../../../assets/images/logo.svg'

// Translations
import { useTranslation } from 'react-i18next';

interface FooterComponentProps {
  btnFn?: () => void;
  dataroom?: number;
  duediligence?: number;
  homefooter?: boolean;
  propertyName?: string;
  mq?: string;
  noFixed?: boolean;
  property?: any
  ville?: string;
}

/**
 * Footer component for property details pages.
 * Displays basic property information and a button for requesting more details.
 */
const PropertyFooter: React.FC<FooterComponentProps> = ({ dataroom = 0 , duediligence = 0, propertyName, mq, ville, homefooter= false, property, btnFn, noFixed }) => {
  const { t } = useTranslation();

  return (
    <footer className={`min-h-16 h-fit px-[32px] py-[12px] ${homefooter ? 'bg-[#3C3D3B] absolute bottom-0 md:bottom-0' : homefooter && noFixed? 'bg-[#3C3D3B]' :'bg-custom-red fixed bottom-0'} flex w-full items-center justify-center md:justify-between z-10`}>
      {!homefooter ? (
        <div className="hidden md:block text-custom-pink">
          <p className='text-xs md:text-sm font-bold font-playfair'>{propertyName} </p>
          <p className='text-xs md:text-sm'>{t('property_price', {price:property.prezzo_vendita} )}</p>
         {+mq !== 0 && <p className='text-xs md:text-sm inline'>{`${mq} mq`}</p>}
         {+ville !== 0 && <p className='text-xs md:text-sm inline'>{` - ${ville} Ville`}</p>}
        </div>
      ) : (
        <LogoIcon color='white' className='hidden md:block' height={56} width={316}/>
      )}
      {!homefooter ? (
        <div className='flex items-center justify-center w-full md:w-fit'>
          <IconWithBorder type='info' isActive={true} />
          {dataroom === 1 && <IconWithBorder type='numbers' isActive={dataroom === 1} />}
          {duediligence === 1 && <IconWithBorder type='sign' isActive={duediligence === 1} />}

            <BaseButton
              className='mx-2'
              dataType='pink-red'
              text={t(
                dataroom === 0 ? 'request_details_room' :
                dataroom === 1 && duediligence !== 1 ? 'request_details_diligence' : 
               'signature_purchase' 
              )}
              onClick={btnFn}
            />

          {dataroom === 0 && <IconWithBorder type='numbers' isActive={dataroom !== 0} />}
          {duediligence === 0 &&<IconWithBorder type='sign' isActive={duediligence !== 0} />}
        </div>
      ):(
        <ul className='text-white flex text-sm'>
          <li>
            <a 
              className='border-r mr-2 pr-2 underline' 
              href='https://www.immobilsarda.com/it/privacy_policy.html' 
              target='_blank' 
              rel="noopener noreferrer"
            >Privacy policy</a>
          </li>
          <li>
            <a 
              className='border-r mr-2 pr-2 underline' 
              href='https://www.iubenda.com/privacy-policy/101883/cookie-policy'
              target='_blank' 
              rel="noopener noreferrer"
            >Cookie Policy</a>
          </li>
          <li><p> P.I 00964920904</p></li>
        </ul>
      )}
    </footer>
  );
};

export default PropertyFooter;
