// Components
import IconText from '../IconText/IconText';

// Icons
import build from '../../assets/images/build.png';
import invest from '../../assets/images/invest.png';
import trophy from '../../assets/images/trophy.png';

/**
 * Renders an icon grid section with three icons and associated text.
 *
 * @param {Object} props - The component props.
 * @param {string} props.bottomLineImage - The URL of the image to be used as the bottom line.
 * @param {Object} props.landingData - An object containing the data for the three icons and associated text.
 * @param {string} props.landingData.asset_to_build_on - The text to be displayed with the first icon.
 * @param {string} props.landingData.assets_to_invest_in - The text to be displayed with the second icon.
 * @param {string} props.landingData.trophy_assets - The text to be displayed with the third icon.
 * @returns {JSX.Element} - The rendered icon grid section.
 */
const IconGridSection = ({ landingData }) => {
  const iconsData = [
    { title:'I', text: landingData.asset_to_build_on, isHtml: true, img: build },
    { title:'II', text: landingData.assets_to_invest_in, isHtml: true , img: invest },
    { title:'III', text: landingData.trophy_assets, isHtml: false, img: trophy },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 h-fit p-12 md:my-16 relative">
      {iconsData.map((iconData, index) => (
        <IconText key={index} backgroundImage={iconData.img} text={iconData.text} isHtml={iconData.isHtml} title={iconData.title}/>
      ))}
    </div>
  );
};

export default IconGridSection;