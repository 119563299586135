import React, { useEffect, useState } from 'react';

// Components
import BaseButton from '../BaseButton/BaseButton';

// Icons
import { ReactComponent as show } from 'feather-icons/dist/icons/eye.svg'
import { ReactComponent as hide } from 'feather-icons/dist/icons/eye-off.svg'

// Translations
import { useTranslation } from 'react-i18next';

// Define the props for the BaseInput component
interface BaseInputProps {
  color?:string;
  errorColor?:string;
  errorRules?: (value: string) => boolean; 
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  requiredFields?:boolean;
  type?: string;
  value?: string; 
}

/**
 * Component used to renders an input element with customizable properties
 */
const BaseInput: React.FC<BaseInputProps> = ({ 
  color,
  errorColor,
  errorRules,
  label,
  onChange,
  placeholder,
  requiredFields,
  type = 'text',
  value = '',
}) => {

  const { t } = useTranslation();
    // Initialize state to keep track of the input's value
    const [initialValue, setInitialValue] = useState(value);
    const [error, setError] = useState('');
    const [showPassword, setShowPassword]=  useState<boolean>(false);

    /**
     * Function handler for input changes. Calls the external onChange handler and updates the internal state
     */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        onChange(e);

        // Validate the new value against the optional errorRules
        if (errorRules && !errorRules(newValue)) {
            setError(t('input_error'));
        } else {
            setError('');
        }
    };

    useEffect(() => {
        setInitialValue(value);
    }, [value]);

  return (
    <div className="mb-4 relative">
        <label className={`block text-sm font-medium ${color}`}>{t(label)} {requiredFields ? <span className='text-custom-red'>*</span>: null }</label>
        <input 
          className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-mdshadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          placeholder={t(placeholder)}
          type={showPassword ? "text" : type}
          value={initialValue}
          onChange={handleChange} 
          autoComplete="off"
        />
        {type === 'password' && 
        <BaseButton 
          dataType='black-text' 
          className='absolute top-7 right-0' 
          iconComponent = {showPassword ? show: hide } 
          onClick ={ ()=> setShowPassword(!showPassword)} 
        />}
      {error && <span className={`${errorColor? errorColor :'text-red-500'} mt-1 text-xs`}>{error}</span>}
    </div>
  );
};

export default BaseInput;