import { useState, useEffect } from 'react';

// Data
import { features_types } from '../../../../data/features';

// Store
import useAuthStore from '../../../../store/auth';
import useStore from '../../../../store/store'; 

// Utils
import { getDetails } from '../../../../utils/property';
import { useLocation } from 'react-router-dom';

// Aggiungi il tipo PropertyDetailsType se non lo hai già definito
interface PropertyDetailsType {
  enabled_data_room: number;
  enabled_due_diligence?: number;
  descrizione_web_breve?: string;
  descrizione_web_estesa?: string;
  videos?: string[];
  features: any[];
  immagini: any[];
  [key: string]: any;
}
interface Image {
  original: string;
  thumbnail: string;
}

/**
 * Hook used for fetching and managing the details of a specific property.
 */
export const usePropertyDetails = (id: string | undefined, subId:  string | undefined) => {
  const location = useLocation();
  
  const [isLoading, setIsLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pointOfInterest, setPointOfInterest] = useState({ lat: 0, lng: 0, title: '' });
  const [allFeatures, setAllFetures] = useState([]);
  const [title, setTitle]= useState('');

  const { language, details, setDetails, setFirstLevel, setSecondLevel, galleryType, scrollToTop } = useStore(state => state);
  const { userDetails } = useAuthStore();

  // Determines the appropriate property details object to use based on the location state.
  const newDetails = location.state?.myProp ? location.state?.myProp : details;
  const propertyLocation = newDetails?.nome_localita;

    // Determines the appropriate property ID to use based on the location state.
    const currentId = location.state?.myProp? subId : id

  /**
   * Extracts the original file name from a file path.
   *
   * @param filePath - The full file path.
   * @returns The original file name without the path.
   */
  function getOriginalFileName(filePath: string): string {
      let fileName = filePath.split('/').pop() || filePath;
      fileName = fileName.substring(fileName.lastIndexOf('_') + 1);
      return fileName;
  }

  /**
   * Opens the modal by setting the `isModalOpen` state to `true`.
   */
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  
  /**
   * Closes the modal by setting the `isModalOpen` state to `false`.
   */
  const handleCloseModal = () => {
      setIsModalOpen(false);
  };

   /**
   * Maps an array of image objects or image URLs to an array of `Image` objects with `original` and `thumbnail` properties.
   *
   * @param images - An array of image objects or image URLs.
   * @returns An array of `Image` objects with `original` and `thumbnail` properties.
   */
   const mapImages = (images: any[]): Image[] => {
    return images.map(pic => ({
      original: typeof pic === 'string' ? pic : pic.link_immagine,
      thumbnail: typeof pic === 'string' ? pic : pic.link_immagine,
    }));
  }

  /**
   * Downloads a set of PDF documents from the provided URLs.
   *
   * @param documents - An array of document URLs to download.
   * @returns Void.
   */
  const downloadAllDocuments = async (documents: string[]) => {
    // Implementation details omitted for brevity
  };

  useEffect(() => {
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
  }, [scrollToTop]);

  useEffect(() => {
    setTitle(newDetails.titolo_web? newDetails.titolo_web : language === 'en' ? newDetails?.titolo_web_en : newDetails?.titolo_web_it );
  },[language, newDetails])

  /**
   * Updates the `firstLevel` and `secondLevel` state based on the `enabled_data_room` and `enabled_due_diligence` properties of the `details` object.
   * 
   * This effect is triggered whenever the `id`, `subId`, `details.enabled_data_room`, or `details.enabled_due_diligence` values change.
   */
  useEffect(() => {
    const newFirstLevel = details?.enabled_data_room !== undefined && details?.enabled_data_room > 0;
    const newSecondLevel = details?.enabled_due_diligence !== undefined && details?.enabled_due_diligence > 0;
  
    setFirstLevel(newFirstLevel);
    setSecondLevel(newSecondLevel);
    // eslint-disable-next-line
  }, [id, subId, details?.enabled_data_room, details?.enabled_due_diligence]);  

  /**
   * Method used to get the details of a property based on its ID. 
   * Checks if a valid property ID is provided. If so, it proceeds to fetch the property details. 
   * Once the details are fetched, it determines the appropriate description text based on the current application language setting and updates the component's state with the new details and the determined description text.
   */
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (id) {
        const idNumber = parseInt(id, 10);
        if (!isNaN(idNumber)) {
          const newDetails = await getDetails(idNumber, language, userDetails.opportunityId);
          
          // Assicurati che features e immagini siano presenti
          const updatedDetails: PropertyDetailsType = {
            ...newDetails,
            features: newDetails.features || [],
            immagini: newDetails.immagini || [],
          };

          setDetails(updatedDetails);
        }
      }
      setIsLoading(false);
    };
    fetchData();
  }, [id, language, setDetails, userDetails.opportunityId]);

    /**
   * Initializes the `pointOfInterest` state and the `allFeatures` state based on the `newDetails` object.
   * The `pointOfInterest` state is set to an object with the latitude, longitude, and title properties, using the relevant fields from the `newDetails` object.
   * The `allFeatures` state is set to an array that combines the `ubicazioni` and `features` properties from the `newDetails` object.
   * 
   * This effect is triggered whenever the `newDetails` object changes.
   */
    useEffect(() => {
      let modifiedUbicazioni=[];
      let modifiedFeatures=[];

      if (newDetails?.ubicazioni) {
        modifiedUbicazioni = newDetails.ubicazioni.map(item => ({
          ...item,
          nome: features_types[item.nome] || item.nome 
        }));
      }
    
      if (newDetails?.features){ 
        modifiedFeatures = newDetails.features.map(item => ({
          ...item,
          nome: features_types[item.nome] || item.nome 
        }));
      }
    
      setAllFetures([...modifiedUbicazioni, ...modifiedFeatures]);
    
      const latitude = newDetails?.posizione_lat ||newDetails?.localita_latitudine || newDetails.latitudine ||0;
      const longitude = newDetails?.posizione_long || newDetails?.localita_longitudine || newDetails.longitudine || 0;
      setPointOfInterest({ lat: Number(latitude), lng: Number(longitude), title});
  
  
      if(newDetails && galleryType) setImages(galleryType === 'photos' ? mapImages(newDetails.immagini) : mapImages(newDetails.immagini_planimetria));
      // eslint-disable-next-line
    }, [newDetails, galleryType, id, subId]);
  

  return { details, isLoading, isModalOpen, getOriginalFileName, handleCloseModal, handleOpenModal, downloadAllDocuments, allFeatures, pointOfInterest , images,  newDetails, propertyLocation, title, currentId};
};
