import React from 'react';

interface BaseLoaderProps {
  loading: boolean;
}

/*
 * Component for displaying a loading animation. The component accepts a `loading` prop to control its visibility, showing the loader only when data is being fetched.
 */
const BaseLoader: React.FC<BaseLoaderProps> = ({ loading }) => {
  if (!loading) return null;

  return (
   <div className="fixed inset-0  bg-[#9da1a766] bg-opacity-75 flex items-center justify-center z-50">
      <iframe src="https://lottie.host/embed/b8fc6ddb-efd1-4b30-a45e-8ec0ab50eb14/nuSQ0FvhXi.json" title='loader'></iframe>
    </div>
  );
};

export default BaseLoader;
