import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Icons
import { ReactComponent as NumbersIcon } from '../../../assets/icons/numbers.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import { ReactComponent as SignatureIcon } from '../../../assets/icons/sign.svg';

// Images
import  noImage from '../../../assets/images/no-picture.jpg'

// Store
import useStore from '../../../store/store';

// Translations
import { useTranslation } from 'react-i18next';

// Types
import { types } from '../../../data/propertyTypes';

// Utils
import { PropertyCardProps } from '../../../types/utils';
import { getPropertyType } from '../../../utils/property';

/**
 * This component displays key information about a property, including images, price, location, and additional features.
 * It acts as a clickable element that navigates users to a detailed view of the property upon interaction.
 *
 */
const PropertyCard = ({ property }: PropertyCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { setScrollToTop }= useStore((state) => state);

  const [isNotSubProperty, setIsNotSubProperty] = useState<boolean>(false);
  
  const firstLevel = property && property.enabled_data_room && property.enabled_data_room > 0;
  const secondLevel = property && property.enabled_due_diligence && property.enabled_due_diligence > 0;

  const id =  property.id || property.immobili_id;
  const image = property.immagini?.[0]?.link_immagine || noImage;
  const immobil_id = property.immobili_id;
  const locality = isNotSubProperty? property.localita_nome : '';
  const mq = isNotSubProperty ? property.mq_interni : property.metri_quadri;
  const pool = isNotSubProperty ? property.estate_num_piscine : null
  const title = isNotSubProperty? property.titolo_web_it : property.testo_libero;
  const titleCard = isNotSubProperty?`${title} - ${locality}` : title;
  const ville  = isNotSubProperty ? property.estate_num_ville : null;
 
  /**
   * Handles the click event on a PropertyCard component, by navigating to the detailed view of the property or sub-property. If the property is not a sub-property, then the path is `/property/${immobil_id}`,
   * otherwise it is `/property/${immobil_id}/sub-property/${id}`. The property data is passed as a state object to the navigated page.
   */
  const handleClick = () => {
    const path = !isNotSubProperty ? `/property/${immobil_id}/sub-property/${id}` : `/property/${immobil_id}`;
    setScrollToTop(true);
    setTimeout(() => {
      navigate(path, { state: { myProp: property } });
      setScrollToTop(false);
    }, 0);
  };

  /**
   * Renders an icon depending on the level of the property.
   * If the property has a second level (due diligence), it renders a signature icon.
   * If the property has a first level (enabled data room), it renders a numbers icon.
   * Otherwise, it renders an info icon.
   * @returns {React.ReactElement} The rendered icon.
   */
  const renderIcon = (): React.ReactElement => {
    if (secondLevel) {
      return <SignatureIcon className="text-custom-red" />;
    } else if (firstLevel) {
      return <NumbersIcon className="text-custom-red" />;
    } else {
      return <InfoIcon className="text-custom-red" />;
    }
  };
  
  useEffect(() => {
    if (property?.enabled_data_room === undefined || property?.enabled_data_room === null) {
      setIsNotSubProperty(false);  // Case where `enabled_data_room` is not present, sub properties do not have this key
    } else {
      setIsNotSubProperty(true);   // `enabled_data_room` is present (even if it is 0 or 1). sub properties do not have this key
    }
  }, [property]);
  

  return (
    <div onClick={handleClick}>
      <div className="relative my-4 md:my-0 md:mr-4 w-[287px] cursor-pointer">
        <div className='h-[270px] bg-cover bg-center' style={{backgroundImage: `url(${image})`}}>  
          {isNotSubProperty && <div className={`absolute top-2 right-2 bg-[#ffffffcc] w-fit p-3 h-8 flex items-center justify-center font-playfair rounded-3xl`}>
            {renderIcon()}
              <p className='font-bold text-custom-red p-1 text-xs' >{t(secondLevel? 'due_diligence':firstLevel? 'data_room': 'info')}</p>
          </div>}
        </div>
        <div className={`bg-white py-1 border bg-gray h-20`}>
          <div className='flex justify-between items-start px-3'>
            <h2 className="font-playfair text-lg">{titleCard}</h2>
            <p className='text-[10px] bg-custom-pink text-custom-red min-w-10 px-1.5 my-1 text-center rounded-3xl capitalize'>{getPropertyType({property, typeKey:'nome_tipologia', types})}</p>
          </div>
          {isNotSubProperty && <div className='flex px-3'>
            {mq  && <p className='text-[10px] mr-2'>{mq + 'mq'}</p>}
            {ville && +ville !== 0 && <p className='text-[10px] mr-2'>{t('manor',{number: ville})}</p>}
            {pool && +pool !== 0 && <p className='text-[10px]'>{t('pool_number', { n: pool } )}</p>}
          </div>}
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
