/**
  * Renders the ImmobilSardaSection component, which displays a section with a background image, a title, a link to the Immobilsarda website, and a list of features.
*/
const ImmobilSardaSection = ({ keyImage, landingData, arrowRight, logo }) => (
    <div className='p-12 pb-28 bg-[#F8F8F8] relative h-fit '>
      <img src={keyImage} alt="key" className="absolute top-0 left-0 md:left-8 h-full z-0" />
      <div className='flex flex-col md:flex-row items-startmd:items-center justify-between h-fit'>
        <h3 className='text-3xl md:text-4xl font-playfair z-10 mb-2 md:mb-0'>{landingData.who_is_immobilsarda}</h3>
        <a
          className='cursor-pointer z-10'
          href='https://www.immobilsarda.com'
          target='_blank'
          rel="noopener noreferrer"
        >
          <p className='underline inline mr-2 font-roboto text-base md:text-sm font-bold text-custom-blue'>{landingData.go_to_reference}</p>
          <img className='inline' src={arrowRight} alt='arrow' />
        </a>
      </div>
      <div className='flex flex-col md:flex-row items-center md:mt-4 text-base'>
        <div className='py-8 w-full md:w-9/12 md:pr-10 z-10'>
          <p dangerouslySetInnerHTML={{ __html: landingData.who_is_immobilsarda_description1 }} />
          <p className='mt-4 md:mt-6' dangerouslySetInnerHTML={{ __html: landingData.who_is_immobilsarda_description2 }} />
        </div>
        <ul className='list-disc list-inside md:py-8 text-custom-red font-bold w-full md:w-3/12 z-10'>
         <li className='md:pl-6 mb-4'>{landingData.portfolio_of_properties}</li>
          <li className='md:pl-6 mb-4'>{landingData.consultant_service}</li>
          <li className='md:pl-6 md:pr-28'>{landingData.real_estate_experience}</li>
        </ul>
       <img src={logo} alt="logo" className="hidden md:block absolute bottom-[64px] right-12"/>
      </div>
    </div>
  );
  
  export default ImmobilSardaSection;
  