import { useState, useRef, useEffect, useMemo } from 'react';
import Slider from 'react-slick';

// Store
import useStore from '../../store/store';

// Types
import { MediaItemType, SliderComponentProps } from '../../types/slider-type';

// Utils
import { handleDropdown } from '../../utils/dropdown';

const useSlider = ({ video, images }: SliderComponentProps) => {
  const { isModalOpen, setIsModalOpen, isGalleryOpen } = useStore();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [type, setType] = useState<string | undefined>(undefined);
  const [isVideo, setIsVideo] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const sliderRef = useRef<Slider | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);



  const combinedMedia = useMemo<MediaItemType[]>(() => [
    ...(video ? video.map((videoLink) => ({ type: 'video' as const, link: videoLink })) : []),
    ...(images ? images.map((img) => ({ type: 'image' as const, link: img.link_immagine })) : []),
  ], [video, images]);

  const handleModal = (type: string | undefined) => {
    setIsModalOpen(!isModalOpen);
    setType(type);
  };

  // Handles the end of a video. This function sets the `isPlaying` state to `false`, calls the `slickPlay` method on the `sliderRef` if it is not `null`, and calls the `slickNext` method on the `sliderRef` if it is not `null`.
  const handleVideoEnd = () => {
    setIsPlaying(false);
    sliderRef.current?.slickPlay();
    sliderRef.current?.slickNext();
  };
  // A function to stop video playback.
  const stopVideoPlayback = () => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    adaptiveHeight: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: !isPlaying,
    autoplaySpeed: 4000,
    beforeChange: (oldIndex: number, newIndex: number) => {
      if (combinedMedia[oldIndex]?.type === 'video' && videoRef.current) {
        videoRef.current.pause();
      }
      setIsPlaying(false);
      setCurrentIndex(newIndex);
      setIsVideo(combinedMedia[newIndex]?.type === 'video');
    },
    afterChange: (current: number) => {
      if (combinedMedia[current]?.type === 'video' && videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play().catch(error => {
          console.error("Video playback failed:", error);
        });
      } else {
        setIsLoading(false); 
      }
    },
  };

  useEffect(() => {
    if (isModalOpen || isGalleryOpen) {
      if (videoRef.current) {
        videoRef.current.pause();
      }
      if (sliderRef.current) {
        sliderRef.current.slickPause();
      }
    }

    if (!isModalOpen || !isGalleryOpen) {
      if (isVideo && videoRef.current) {
        setIsLoading(false); 
        videoRef.current.play().catch(error => {
          console.error("Video playback failed:", error);
        });
   
      } else {
        sliderRef.current?.slickPlay();
      }
    }
  }, [isModalOpen, isVideo, videoRef, isGalleryOpen]);

  useEffect(() => {
    if (combinedMedia.length > 0 && combinedMedia[currentIndex].type === 'video') {
      !isPlaying && setIsLoading(true);
      setIsVideo(true);
      setIsPlaying(true);
    } else {
      setIsVideo(false);
      setIsPlaying(false);
      setIsLoading(false);
    }
    // eslint-disable-next-line 
  }, [currentIndex, combinedMedia]);

  useEffect(()=>{
    handleDropdown(isModalOpen);
  },[isModalOpen]);

  return {
    currentIndex,
    type,
    isPlaying,
    isLoading,
    isModalOpen,
    combinedMedia,
    sliderRef,
    videoRef,
    handleModal,
    handleVideoEnd,
    stopVideoPlayback,
    settings
  };
};

export default useSlider;
