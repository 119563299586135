import { useState } from "react";
import { APIProvider, Map, AdvancedMarker, InfoWindow } from "@vis.gl/react-google-maps";
import {  ReactComponent as LogoSvg} from "../../assets/images/key-logo.svg";

// Store
import useStore from "../../store/store";

// Translations
import { useTranslation } from "react-i18next";

// Import custom CSS for InfoWindow
import './GoogleMap.css';

/**
 * Renders a Google Map component with a marker and an optional info window.
 *
 * @param {Object} position - The position object containing the latitude, longitude, and optional title.
 * @returns {JSX.Element} - The Google Map component.
 */
const GoogleMap = ({ position, property }) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const firstLevel = useStore((state) => state.firstLevel);
    const [mapLoaded, setMapLoaded] = useState(false); // State to handle map loading
  
    // Function that is called when the map is loaded
    const handleTilesLoaded = () => {
        setMapLoaded(true);
      };
    
   // Funzione per capitalizzare la prima lettera
  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };
    return (
        <>
          <APIProvider apiKey={process.env.REACT_APP_PUBLIC_GOOGLE_MAPS_API_KEY}>
            <div style={{ height: "500px", width: "100%", position: "relative" }}>
              <Map
                defaultZoom={14}
                mapId={process.env.REACT_APP_PUBLIC_MAP_ID}
                defaultCenter={position}
                gestureHandling="cooperative"
                disableDefaultUI={!firstLevel}
                fullscreenControl={true}
                mapTypeId="hybrid"
                onTilesLoaded={handleTilesLoaded}  // Event for loading tiles completely
              >
                {mapLoaded && (
                  <>
                    <AdvancedMarker position={position} onClick={() => setOpen(true)}>
                      <div className="logo-container">
                        <LogoSvg className="h-12 bg-custom-red p-2 rounded-full w-12 z-10 ripple-effect" />
                      </div>
                    </AdvancedMarker>
                    {open && (
                      <InfoWindow position={position} onCloseClick={() => setOpen(false)}>
                        <p className="font-playfair text-sm p-py">
                          {firstLevel ? position.title : t("request_details_room")}
                        </p>
                      </InfoWindow>
                    )}
                  </>
                )}
              </Map>
    
              <div className="absolute left-3 bottom-0 md:top-0 w-map-info md:w-fit h-fit my-0 md:my-[5.5rem] py-4 px-8 md:p-8 font-roboto bg-custom-trasparent-red">
                <h1 className="text-white text-xl font-playfair pb-4">{t("distances")}</h1>
                {property.distanza_mare && (
                  <p className="text-white">{t("distance_to_sea", { number: property.distanza_mare})}</p>
                )}
                {property.paese_piu_vicino && (
                  <p className="text-white">{t("nearest_country", { country: capitalizeFirstLetter(property.paese_piu_vicino) })}</p>
                )}
               {property.distanza_paese_piu_vicino && (
                  <p className="text-white">
                    {t("distance_nearest_town", { number: property.distanza_paese_piu_vicino })}
                  </p>
                )}
              </div>
            </div>
          </APIProvider>
        </>
      );
    };
    
    export default GoogleMap;