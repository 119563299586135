import { useState, useEffect, useCallback } from 'react';

// Api
import propertiesApi from '../../../api/modules/properties';

// Icons
import emailIcon from '../../../assets/icons/email.svg'
import whatsappIcon from '../../../assets/icons/whatsapp.svg'
import phoneIcon from '../../../assets/icons/phone.svg'
import { ReactComponent as emailRed } from '../../../assets/icons/email.svg';
import { ReactComponent as phone } from '../../../assets/icons/phone.svg'
import { ReactComponent as whatsappRed } from '../../../assets/icons/whatsapp.svg';

// Store
import useStore from '../../../store/store';
import useAuthStore from '../../../store/auth';

// Translation
import { useTranslation } from 'react-i18next';

// Types
import { IconClickEvent, Property } from '../../../components/Property/types/types';

// Utils
import { getDetails } from '../../../utils/property';

/**
 * Hook for managing the state and behavior of the home page.
 * 
 * Features include:
 * - Fetching all properties and updating the state with their details.
 * - Managing a loading state to indicate ongoing operations.
 * - Providing a function to change the application's language, affecting the entire application's UI.
 **/
const useHome = () => {
  const { i18n  } = useTranslation();

  const { userDetails, loading } = useAuthStore();
  const [isLoading, setIsLoading] = useState<boolean>(loading || true);
  const [firstLetter, setFirstLetter] = useState<string | null>(null);
  const [assistantPhone, setAssistantPhone] = useState<string | null>(null);
  const [assistantEmail, setAssistantEmail] = useState<string | null>(null);
  const [assistantName, setAssistantName] = useState<string | null>(null);

  const { allProperties, setAllProperties }= useStore((state) => state);

  // Method to get all properties and update all properties with thei details, and finally update the state with the list of properties
  const getAllProperties = useCallback(async () => {   
    const opId = userDetails.opportunityId;
    const initialProperties: Property[] = await propertiesApi.getProperties(opId);
  
    if (initialProperties.length !== 0) {
      const detailsPromises = initialProperties?.map(async (p: Property) => {
        const newDetails = await getDetails(p.immobili_id, null, opId);
        return newDetails;
      });
  
      const allDetails = await Promise.all(detailsPromises.filter(Boolean));
  
      const updatedProperties = initialProperties.map((prop, index) => ({
        ...prop,
        ...allDetails[index],
      }));
  
      // Sorting with priority: enabled_due_diligence = 1, then enabled_data_room = 1, and finally the others
      const sortedProperties = updatedProperties.sort((a, b) => {
        // Priority for `enabled_due_diligence = 1`
        if (a.enabled_due_diligence === 1 && b.enabled_due_diligence !== 1) {
          return -1; // a comes before b
        }
        if (a.enabled_due_diligence !== 1 && b.enabled_due_diligence === 1) {
          return 1; // b comes before a
        }
  
        // If both have `enabled_due_diligence = 1`, keep the current order
        if (a.enabled_due_diligence === 1 && b.enabled_due_diligence === 1) {
          return 0; // No change in order
        }
  
        // If neither has `enabled_due_diligence`, sort by `enabled_data_room = 1`
        if (a.enabled_data_room === 1 && b.enabled_data_room !== 1) {
          return -1; // a comes before b
        }
        if (a.enabled_data_room !== 1 && b.enabled_data_room === 1) {
          return 1; // b comes before a
        }
  
        // If neither has `enabled_due_diligence` or `enabled_data_room`, keep the current order
        return 0;
      });
  
      setAllProperties(sortedProperties);
    }
    setIsLoading(false);
    
    // eslint-disable-next-line
  }, [userDetails.opportunityId]);
  
  /**
   * Handles the click event on an icon by redirecting the user to the specified URL.
   * @param {IconClickEvent} param0 - An object containing the URL to redirect to.
   * @param {string} param0.url - The URL to redirect the user to.
   */
  const handleIconClick = ({ url }: IconClickEvent) => {
    window.location.href = url;
  };

  const language = useStore((state) => state.language);
  const setLanguage = useStore((state) => state.setLanguage);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
    setLanguage(lng);
    localStorage.setItem('appLang', lng);
  };  

  useEffect(() => {
    if (userDetails.name) {
      const userNameFirstLetter = userDetails.name.charAt(0);
      const userLastnameFirstLetter = userDetails.surname.charAt(0);
      setFirstLetter(`${userNameFirstLetter}${userLastnameFirstLetter}`);
      setAssistantPhone(userDetails.assistant?.phone_number || null);
      setAssistantEmail(userDetails.assistant?.email || null);
      setAssistantName(userDetails.assistant?.given_name || null);
    }
  }, [userDetails]);

  useEffect(() => {
    getAllProperties();
    // eslint-disable-next-line
  }, []);

   // An array of link objects, each representing a contact method for an assistant.
    // Each link object has a `url`, `name`, `details`, and `icon` property.
    const links = [
      { url: `https://wa.me/${assistantPhone}`, name: 'wa', details:'write_on_wa', icon: whatsappIcon, contact:assistantPhone },
      { url: `mailto:${assistantEmail}`, name: 'email', details:'write_an_email', icon:emailIcon, contact:assistantEmail},
      { url: `tel:${assistantPhone}`, name: 'phone', details: 'doing_phone', icon: phoneIcon, contact:assistantPhone}
    ];
      
    const redLinks = [
       { url: `https://wa.me/${assistantPhone}`, name: 'wa', details:'write_on_wa', icon: whatsappRed },
       { url: `mailto:${assistantEmail}`, name: 'email', details:'write_an_email', icon:emailRed},
       { url: `tel:${assistantPhone}`, name: 'phone', details: 'doing_phone', icon: phone },
    ];

  return { allProperties, isLoading, language, links, redLinks, changeLanguage, firstLetter, name: assistantName, handleIconClick };
};

export default useHome;
