import React, { useEffect } from 'react';

// Components
import BaseButton from '../../base/BaseButton/BaseButton';

// hooks
import useHeader from '../PropertyDetails/hook/header-hook';
import useHome from '../../../Views/Home/hook/home-hook';

// Images
import blueLogo from '../../../assets/images/red-logo.svg';
import { ReactComponent as arrowLeft } from 'feather-icons/dist/icons/arrow-left.svg';
import { ReactComponent as Logo } from '../../../assets/images/logo.svg';

// Store
import useStore from '../../../store/store';

// Translations
import { useTranslation } from 'react-i18next';

// Types
import { HeaderComponentProps } from '../types/types';

// Utils
import { handleDropdown } from '../../../utils/dropdown';

/**
 * Header component for property-related pages.
 * This component is designed to enhance the user experience by providing quick access to contact options directly from the property header.
 */
const PropertyHeader: React.FC<HeaderComponentProps> = ({ 
  showContact = false,
  btnClick,
  isLanding,
  btnBText,
  btnRText,
  btnRedFn,
  btnBlackFn,
}) => {
  const { t } = useTranslation();

  const { dropdownVisible } = useStore();
  const { redLinks, name, firstLetter, handleIconClick, changeLanguage} = useHome();
  const {
    languageButtons,
    toggleDropdown,
    dropdownButtons,
    headerButtons,
  } = useHeader({ isLanding, btnBText, btnRText, btnRedFn, btnBlackFn });

  useEffect(()=>{
    handleDropdown(dropdownVisible);
  },[dropdownVisible]);

  return (
    <header
    className={`flex items-center w-full justify-between py-4 md:py-0 px-8 ${
       !showContact &&!isLanding
        ? 'border-b-[1px] bg-custom-red text-custom-pink h-20'
        :isLanding? 
        'h-24 bg-custom-red'
        : 'bg-white md:border-bottom md:border-custom-red h-14'
    }`}

  >
    {showContact && (
      <BaseButton
        dataType="white"
        onClick={btnClick}
        iconComponent={arrowLeft}
        text={t('back')}
      />
    )}
      <Logo height='65' width='350'className={showContact ? 'block md:hidden text-white' :'text-white'} />
      {showContact && <img src={blueLogo} alt="logo" className='hidden md:block h-12' />}
      <div className="flex items-center">
        <div className={!showContact ? 'flex mr-7' : 'hidden'}>
          {languageButtons.map((btn, index) => (
            <BaseButton
              key={index}
              dataType={btn.dataType as 'default' | 'black' | 'black-underline' | 'red-text-line' | 'black-text' | 'pink-text' | 'pink-red' | 'white' | 'white-red' | 'text-red'| 'white-red-rounded' }
              onClick={() => changeLanguage(btn.lang)}
              text={t(btn.lang)}
              className='mr-2 w-9 justify-center hidden md:flex'
            />
          ))}
        </div>
        {showContact && (
          <div className="flex items-center">
            <p className="text-xs mr-1 text-custom-red font-medium">
              {t('contact_agent', { name: name })}
              :
            </p>
            {redLinks.map((link, index) => (
              <BaseButton
                className="mr-2 rounded-full px-2"
                key={index}
                dataType="pink-red"
                iconComponent={link.icon}
                onClick={() => handleIconClick({ url: link.url })}
              />
            ))}
          </div>
        )}
        {(!isLanding && !showContact) || showContact ? (
          <>
            <div
              className={`hidden md:flex ml-2 md:ml-5 w-8 h-8 rounded-full uppercase ${
                !showContact
                  ? 'bg-custom-pink text-custom-red'
                  : 'bg-custom-red text-custom-pink'
              } justify-center items-center cursor-pointer`}
              onClick={toggleDropdown}
            >
              {firstLetter}
            </div>
              {dropdownVisible && (
              <div className="absolute right-8 mt-32 w-fit bg-white shadow-lg rounded-md p-2 z-[99]">
                <ul className='flex flex-col'>
                  {dropdownButtons.map((btn, index) => (
                    <li key={index} className={index === 0 ? 'self-end' : 'rounded-md pt-2'}>
                      <BaseButton 
                        dataType={btn.dataType as 'default' | 'black' | 'black-underline' | 'red-text-line' | 'black-text' | 'pink-text' | 'pink-red' | 'white' | 'white-red' | 'text-red'| 'white-red-rounded'} 
                        iconComponent={btn.icon as React.ElementType} 
                        onClick={btn.click} 
                        text={btn.text} 
                        className={btn.class}
                        iconClass={btn.iconClass} />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </>
        ) : (
          <div className='hidden md:flex flex-row items-center justify-between'>
         {headerButtons.map((btn, index) => (
            <BaseButton key={index} onClick={btn.click} dataType={btn.dataType as 'default' | 'black' | 'black-underline' | 'red-text-line' | 'black-text' | 'pink-text' | 'pink-red' | 'white' | 'white-red' | 'text-red' | 'white-red-rounded'} text={btn.text}  className={btn.class} />
          ))}
          </div>
        )}
      </div>
    </header>
  );
};

export default PropertyHeader;