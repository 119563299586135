import useStore from "../../../../store/store";

// Icons
import  { ReactComponent as close } from 'feather-icons/dist/icons/x.svg'
import { ReactComponent as LogoutIcon } from 'feather-icons/dist/icons/log-out.svg';

// Store
import useAuthStore from "../../../../store/auth";

/**
 * Returns an object containing language buttons, header buttons, dropdown buttons, and a toggleDropdown function.
*/
const useHeader = ({isLanding, btnBText, btnRText, btnRedFn, btnBlackFn }) => {
  
  const { logout } = useAuthStore();

  const {language, dropdownVisible, setDropdownVisible, setIsBackgroundFaded} = useStore((state) => state);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
    setIsBackgroundFaded(!dropdownVisible);
  };

  const languageButtons = [
    { lang: 'it', dataType: isLanding && language === 'it' ? 'white-red' : !isLanding && language === 'it' ? 'white-red':'white-text-line' },
     { lang: 'en', dataType: isLanding && language === 'en' ? 'white-red' : !isLanding && language === 'en' ? 'white-red':'white-text-line' }
  ];
  
  const dropdownButtons = [
    { dataType: 'black-text', icon: close, click: toggleDropdown, class:'h-1', iconClass:'w-[16px]'},
    { dataType: 'black-text', icon: LogoutIcon, click: logout, text: 'Logout' }
  ];
    
  const headerButtons = [
    { class: 'ml-8 md:ml-0 md:block mr-2 md:mr-4 h-fit md:h-9 max-w-[179px] py-px md:py-0', dataType:'white-text-line', text: btnRText, click: btnRedFn },
    { class: 'md:block h-fit md:h-9 max-w-[179px] py-px md:py-0', dataType: 'white-red', text: btnBText, click: btnBlackFn } 
  ];
  

  return{ languageButtons, headerButtons, dropdownButtons, toggleDropdown}
}

export default useHeader;