import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import AssistantInfo from '../../components/AssistantInfo/AssistantInfo';
import BaseLoader from '../../components/base/BaseLoader/BaseLoader';
import FormattedText from '../../components/HomeFormattedText/HomeFormattedText';
import PropertyCardSlider from '../../components/PropertyCardSlider/PropertyCardSlider';
import PropertyHeader from '../../components/Property/PropertyHeader/PropertyHeader';
import PropertyFooter from '../../components/Property/PropertyFooter/Footer';

// Hooks
import useHome from './hook/home-hook';

// Store
import useAuthStore from '../../store/auth';
import useStore from '../../store/store';

// Utilities
import { convertTime } from '../../utils/time';

/**
 * The main Home component that renders the home page of the application.
*/
const Home: React.FC = () => {
  const { t } = useTranslation();
  const { dropdownVisible, isBackgroundFaded } = useStore((state) => state);

  const { isLoading, links, language, allProperties } = useHome();
  const { userDetails } = useAuthStore();

  const [assistant, setAssistant] = useState({
    given_name: null,
    family_name: null,
    email: null,
    phone_number: null,
  });
  const [formattedText, setFormattedText] = useState('');

  const time = userDetails?.time ? convertTime(userDetails.time) : undefined;

  useEffect(() => {
    let tempAssistant = {
      given_name: null,
      family_name: null,
      email: null,
      phone_number: null,
    };

    if (userDetails?.assistant) {
      tempAssistant = {
        ...tempAssistant,
        ...userDetails.assistant,
      };
    }

    const tempFormattedText = t('welcome_desc', {
      name: tempAssistant.given_name || '',
      surname: tempAssistant.family_name || '',
      email: tempAssistant.email || '',
      phone: tempAssistant.phone_number || '',
      date: userDetails?.time ? convertTime(userDetails.time) : undefined || '',
    });

    setAssistant(tempAssistant);
    setFormattedText(tempFormattedText);
    // eslint-disable-next-line
  }, [userDetails, language]);

  return (
    <>
      {isLoading ? (
        <BaseLoader loading={isLoading} />
      ) : (
        <>
          {isBackgroundFaded && <div className="fixed inset-0 bg-black opacity-50 z-[5]" />}
          <PropertyHeader />
          <div className={`bg-gradient-to-b from-[#faeff06b] to-[#FAEFF0] hide-scrollbar ${dropdownVisible ? 'overflow-y-hidden' : 'overflow-y-auto'} flex relative`}>

            {/* Flex container for equal height divs */}
            <div className="flex w-full">
              
              {/* Main content area */}
              <div className='md:w-3/4'>
                <section className="p-10 md:p-0 text-left flex w-full justify-between">
                  <div className="flex flex-col md:flex-row w-full">
                    <div className="md:p-10">
                      <h1 className="text-4xl my-2.5 font-playfair text-black">
                        {t('welcome', { name: userDetails?.name?.charAt(0).toUpperCase() + userDetails?.name?.slice(1) })}
                      </h1>
                      <FormattedText text={formattedText} />
                      <p className=" text-sm font-bold my-2.5 text-custom-red">
                        {t('availability', { date: time })}
                      </p>
                    </div>
                    <AssistantInfo assistant={assistant} links={links} time={time} className={'md:hidden'} />
                  </div>
                </section>

                <h1 className='mb-6 px-10 font-playfair text-3xl font-sm leading-8'>{t('opportunities_selected_for')}</h1>
                <PropertyCardSlider isHome properties={allProperties} />
              </div>

              <div className='w-1/4 bg-white hidden md:block'>
                <AssistantInfo assistant={assistant} links={links} time={time} className={'md:pt-10'} />
              </div>
            </div>
            <PropertyFooter homefooter />
          </div>

        </>
      )}
    </>
  );  
};

export default Home;
