import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import getData from './utils/getJsonFile';

const apiENUrl = process.env.REACT_APP_RESERVED_EN_TEXT;
const apiITUrl = process.env.REACT_APP_RESERVED_IT_TEXT;
const apiLandingUrl = process.env.REACT_APP_LANDING_TEXT;

const initI18n = async () => {
    try {
        const translationEN = await getData(apiENUrl);
        const translationIT = await getData(apiITUrl);
        const translationLandingUrl = await getData(apiLandingUrl);

        const resources = {
            en: { translation: translationEN },
            it: { translation: translationIT },
            data: { translation: translationLandingUrl }
        };

        return new Promise((resolve, reject) => {
            i18n
                .use(initReactI18next)
                .init({
                    resources,
                    lng: 'it', // Default language
                    fallbackLng: 'en', // Fallback language if key is missing
                    interpolation: { escapeValue: false }, // Prevent XSS
                    initImmediate: false, // Prevents async init
                    debug: false, // Disable debug logs
                    missingKeyHandler: (lng, ns, key, fallbackValue) => {
                        // Optionally, log or handle missing keys in a custom way
                        // console.warn(`Missing translation for key: ${key}`);
                    },
                }, (err, t) => {
                    if (err) {
                        console.error('Error during i18next initialization:', err);
                        reject(err);
                    } else {
                        resolve({});
                    }
                });
        });
    } catch (error) {
        console.error('Error during the loading of translations:', error);
        throw error;
    }
};

export const i18nInitPromise = initI18n();

export default i18n;