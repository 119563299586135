/**
 * Zustand store configuration for managing application-wide state.
 * Components can use the `useStore` hook to access and manipulate the store's state.
 */
import { create } from 'zustand';

// Types
import { PropertyDetailsType, StoreState, DropdownState, Property  } from '../components/Property/types/types';

// Merged type 
type ExtendedStoreState = StoreState & DropdownState;

/**
 * The store exposes two pieces of state:
 * - `language`: The current language setting, initially derived from the browser's language.
 * - `setLanguage`: A function to update the language setting, triggering re-renders in subscribed components.
*/

const useStore = create<ExtendedStoreState>((set) => ({
  language: navigator.language.split('-')[0],
  setLanguage: (lang: string) => set({ language: lang }),
  
  firstLevel: false,
  setFirstLevel: (value: boolean) => set(() => ({ firstLevel: value })),
  
  secondLevel: false,
  setSecondLevel: (value: boolean) => set(() => ({ secondLevel: value })),
  
  details: { immagini: [], features:[] },
  setDetails: (details: PropertyDetailsType) => set(() => ({ details })),
  
  dropdownVisible: false,
  setDropdownVisible: (visible: boolean) => set(() => ({ dropdownVisible: visible })),

  isBackgroundFaded: false,
  setIsBackgroundFaded: (faded: boolean)=> set(() => ({ isBackgroundFaded: faded })),
  
  isModalOpen: false,
  setIsModalOpen: (isOpen: boolean) => set(() => ({ isModalOpen: isOpen })),

  isGalleryOpen: false,
  setIsGalleryOpen: (isOpen: boolean) => set(() => ({ isGalleryOpen: isOpen })),
  
  galleryIndex: 0,
  setGalleryIndex: (index: number) => set(() => ({ galleryIndex: index })),

  galleryType: 'photos',
  setGalleryType: (type: string) => set(() => ({ galleryType: type  })),

  isChecked: false,
  setIsChecked: (checked: boolean) => set(() => ({ isChecked: checked })),

  currentSlideOption: null,
  setCurrentSlideOption: (option: string) => set(() => ({ currentSlideOption: option })),

  progress: 0,
  setProgress: (prog: number) => set(() => ({ progress: prog })),

  scrollToTop: false, 
  setScrollToTop: (scroll: boolean) => set(() => ({ scrollToTop: scroll })),

  // New state for allProperties and its setter
  allProperties: [], // Initial state is an empty array
  setAllProperties: (properties: Property[]) => set((state) => ({ ...state, allProperties: properties })),

   // Assuming 'export' is a string property for demonstration purposes
  export: '',
}));

export default useStore;