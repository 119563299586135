import { useState, useEffect } from 'react';

const useLandingData = (i18n) => {
  const [landingData, setLandingData] = useState(null);

  useEffect(() => {
    if (i18n.isInitialized) {
      const fetchLandingData = async () => {
        try {
          const apiUrl = process.env.REACT_APP_LANDING_TEXT;
          const jsonData = await fetch(apiUrl).then(res => res.json());
          const data = jsonData[i18n.language] || jsonData['en']; // Fallback to English
          setLandingData(data);
        } catch (error) {
          console.error('Error fetching landing data:', error);
        }
      };

      fetchLandingData();
    }
  }, [i18n.language, i18n.isInitialized]);

  return landingData;
};

export default useLandingData;