import SignatureContent from '../SignatureContent/SignatureContent';

const ModalForm = ({ landingData, handleInfo, setOpenModal }) => {
  return (
    <SignatureContent
      title="ask_info"
      subtitle="fill_form"
      checkboxLabel={landingData.checkbox_label}
      isLanding
      submitText={landingData.request_information}
      handleSubmit={(values) => handleInfo(values)}
    />
  );
};

export default ModalForm;