import React, { useEffect } from 'react';

// Component
import BaseLoader from '../../components/base/BaseLoader/BaseLoader';
import GenericForm from '../../components/GenericForm/GenericForm';

// Images
import { ReactComponent as Logo } from '../../assets/images/50-logo.svg';
import { ReactComponent as SecondLogo } from '../../assets/images/Christies-logo.svg';
import { ReactComponent as CompleteLogo } from '../../assets/images/logo.svg';

// Store
import useAuthStore from '../../store/auth';

// Notify
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Translations
import { useTranslation } from 'react-i18next';

// Interface for login form values
interface LoginValues {
  email: string;
  password: string;
}

// Login component
const Login: React.FC = () => {
  const { t } = useTranslation();
  const { loading, error, authenticate } = useAuthStore();

  // Handle login form submission
  const handleLogin = (values: LoginValues) => {
    authenticate(values);
  };

  // Display error toast notification if there's an error
  useEffect(() => {
    if (error) {
      toast.error(`${error} 😞`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [error]);

  return (
    <>
      <ToastContainer />
      {loading ? (
        <BaseLoader loading={loading} />
      ) : (
        <div className="flex h-screen items-center justify-center bg-custom-red">
          <div className="flex flex-col md:flex-row items-center justify-between h-full w-full md:w-4/5">
            <div className="hidden md:flex flex-col items-center justify-center w-full md:w-2/5 py-4 md:py-0">
              <Logo className="mb-4" />
              <SecondLogo />
            </div>
            <div className="hidden md:block h-5/6 w-px bg-white"/>
            <div className="flex flex-col items-center justify-center md:w-2/5">
              <CompleteLogo className="block md:hidden text-white w-9/12 h-auto m-8" />
              <div className="block md:hidden border-b border-white w-[90%]"></div>
              <GenericForm
                inputColor='text-white'
                inputErrorColor='text-white'
                title={t('access')}
                submit={t('login')}
                fields={[
                  {
                    id: 'email',
                    label: 'Email',
                    type: 'email',
                    validationRule: (value) => /\S+@\S+\.\S+/.test(value),
                    placeholder: 'Email',
                  },
                  {
                    id: 'password',
                    label: 'Password',
                    type: 'password',
                    validationRule: (value) =>
                      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/.test(value),
                    placeholder: 'Password',
                  },
                ]}
                onSubmit={handleLogin}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
