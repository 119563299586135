import React from 'react'
import { useTranslation } from 'react-i18next';
interface NextStep {
    text: string;
  }
  interface NextStepsListProps {
    stepKey: string;
  }

  /**
    * Renders a list of next steps with their corresponding text.
    * The list is displayed as an unordered list with a decimal list style.
    * Each next step is displayed as a list item with its text content.
  */
  const NextStepsList: React.FC<NextStepsListProps> = ({ stepKey }) => {
    const { t } = useTranslation();
    const steps: NextStep[] = t(stepKey, { returnObjects: true });
  
    return (
      <ul className="list-decimal list-inside p-4 space-y-2">
        {steps.map((step, index) => (
          <li key={index} className="py-2">
            <p className="inline">{step.text}</p>
          </li>
        ))}
      </ul>
    );
  };


export default NextStepsList;
