import React, { useEffect } from 'react';
import Slider from 'react-slick';
import SliderDescription from './SliderDescription/SliderDescriptions';

// Store
import useStore from '../../../store/store';

// Style
import './SliderWidthDescription.css'

// Translations
import { useTranslation } from 'react-i18next';

interface Slide {
  img: string;
  title?: string;
  desc: string;
  icon?: string;
}
interface SliderWithDescriptionProps {
  slides: Slide[];
  data: object;
  iconComponents: Record<string, React.FunctionComponent<React.SVGProps<SVGSVGElement>>>;
}

const PrevArrow: React.FC<any> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slick-prev ${className}`}
      style={{ ...style, display: 'block', background: null }}
      onClick={onClick}
    />
  );
};

const NextArrow: React.FC<any> = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`slick-next ${className}`}
      style={{ ...style, display: 'block', background: null }}
      onClick={onClick}
    />
  );
};

const SliderWithDescription: React.FC<SliderWithDescriptionProps> = ({ slides, data, iconComponents }) => {
  const { t } = useTranslation();
  const setProgress = useStore(state => state.setProgress);

  const isMobile = window.innerWidth <= 600;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: 1,
    afterChange: (current: number) => {
      if (current === 0) setProgress(25);
      else if (current === 1) setProgress(50);
      else if (current === 2) setProgress(75);
      else if (current === 3) setProgress(100);
    },
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  };

  useEffect(() => {
    setProgress(25);
    // eslint-disable-next-line
  }, []);

  return (
    <div className="relative">
      <Slider {...settings}>
        {slides.map((slide, index) => (
          <div key={index} className="slide font-roboto px-4">
            <SliderDescription
              index={index}
              title={slide.title ? t(slide.title) : undefined}
              icon={slide.icon}
              iconComponents={iconComponents}
            />
            <div className="slide-content">
              <div className='w-full'>
                <img src={slide.img} alt={`Slide ${index + 1}`} className='w-full h-auto' />
              </div>
            </div>
            <p className='my-8 text-xl'>{t(data[slide.desc])}</p>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderWithDescription;
