import axios, { AxiosInstance, AxiosError } from 'axios';

const apiUrl =  process.env.REACT_APP_API_AWS 

const instance: AxiosInstance = axios.create({
  baseURL: `${apiUrl}/`
});

instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error: AxiosError) => {
    const { message, code } = error;
    return {
      statusCode: code,
      msg: message,
    };
  }
);

export default instance;
