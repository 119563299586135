import React from 'react';

interface CustomLoadingBarProps {
  barStyle: object;
}

const BaseLoadingBar: React.FC<CustomLoadingBarProps> = ({barStyle}) => {
  return <div style={barStyle}></div>;
};

export default BaseLoadingBar;