import { useEffect, useState } from 'react';
import useStore from '../../../../../store/store';
import { decodeHtmlEntities } from '../../../../../utils/property';
/**
 * Renders a text description for a property, with optional expansion behavior.
 * 
 * @param {Object} props - The component props.
 * @param {string} props.details.descrizione_web_estesa - The extended web description for the property.
 * @param {string} props.details.descrizione_web_breve - The brief web description for the property.
 * @param {boolean} props.isExpanded - Whether the description should be fully expanded or collapsed.
 * @returns {React.ReactElement} - The rendered property description text.
 */
const DescriptionText = ({ details, isExpanded }) => {
  const { language } = useStore(state => state);
  const [description, setDescription] = useState('');

  useEffect(() => {
   if (language === 'it') {
      setDescription(details.descrizione_web_estesa_it || details.descrizione_web_breve_it);
   } else setDescription(details.descrizione_web_estesa_en || details.descrizione_web_breve_en);
   // eslint-disable-next-line
  }, [language]);

  return (
    <div
      className={`text-left md:mb-0 w-full md:pr-6 ${
        isExpanded ? 'max-h-full' : 'max-h-60 md:max-h-full'
      } whitespace-pre-wrap break-words overflow-hidden inline`}
    >
      {details ? (
        <div
          dangerouslySetInnerHTML={{
            __html: decodeHtmlEntities(
              description
            ),
          }}
        />
      ) : (
        'N/A'
      )}
    </div>
  );
};
export default DescriptionText;