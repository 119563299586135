import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Defines the props required by the BaseLayout component.
 * This includes the title, optional subtext, optional click handler for subtext, and children components.
 */
interface BaseLayoutProps {
    btn?: string;
    btnFn?: () => void;
    buttonStyles?: string;
    children?: React.ReactNode;
    subtext?: string;
    subtextStyle?:string;
    title: string;
}

/**
 * A base layout component that displays a title and optional subtext, along with any child components passed to it.
 * It uses responsive design principles to adjust the visibility of the subtext based on the screen size.
 */
const BaseLayout: React.FC<BaseLayoutProps> = ({ title, subtext, children, subtextStyle, btn, buttonStyles, btnFn }) => {
  const { t } = useTranslation();

  return (
    <section className={`${title === 'where' ? 'p-0 md:p-0':'px-8 pt-10 pb-2 md:pb-8 md:px-8 md:pt-10'} flex flex-col items-start md:mr-0 `}>
  
    <h1 className={`${title === 'where' ?'px-8 pt-10 md:pt-0': ''} text-3xl font-playfair text-wrap`}>{t(title)}</h1>
    {subtext && <p className={`${ title === 'where' ?'px-8':''} my-2 ${subtextStyle}` } >{subtext}</p>}
   
      {children && (
        <div className={`${title === 'photo'|| title === 'floor_plans' || title.includes('Compongono') ? 'border-b pb-5': title === 'where' ?'pt-0 pb-0 m-0': 'md:border-0 pt-5  md:my-5'} w-full border-black relative`}>
          {children}
        </div>
      )}

    {btn && <p className={`${btnFn? 'cursor-pointer' : title === 'where' ?'px-8':''} my-2 underline ${buttonStyles}` }onClick={btnFn}>{btn}</p>}
    </section>
  );
};

export default BaseLayout;
