
const IconText = ({ text, isHtml = false, backgroundImage, title }) => {
  return (
    <div
      className="flex flex-row md:flex-col items-center px-4 py-12 md:py-20 my-4"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <h1 className="font-playfair text-7xl md:text-8xl text-custom-red text-left ">{title}</h1>
      {isHtml ? (
        <p className='ml-8 font-playfair text-left text-3xl md:text-2xl font-bold' dangerouslySetInnerHTML={{ __html: text }} />
      ) : (
        <p className='ml-8 font-playfair text-left text-3xl md:text-2xl font-bold' >{text}</p>
      )}
    </div>
  );
};
export default IconText;
