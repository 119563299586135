import { useState } from 'react'

// Components
import BaseLayout from '../../base/BaseLayout/BaseLayout'

// Images
import key from '../../../assets/images/key-logo.png'

// Translations
import { useTranslation } from 'react-i18next'

interface Feature {
  nome: string
  link_immagine: string
  title?: string
}

/**
 * Renders a list of property features with a toggle button to show/hide all features.
 *
 * @param {Object} props - The properties for the component.
 * @param {Array} props.features - The list of features to be displayed.
 * @param {string} props.title - The title of the component.
 * @return {JSX.Element} The rendered component.
 */
function PropertyFeatures({ features, title }) {
  const { t } = useTranslation()
  const [showAllFeatures, setShowAllFeatures] = useState(false)

  // Toggles the visibility of the features.
  const toggleFeatureVisibility = () => {
    setShowAllFeatures(!showAllFeatures)
  }

  const isMobile = window.innerWidth <= 600
  const numberFeature = isMobile && features.length > 6? features.length - 6 : features.length - 9

  return (
    <BaseLayout title={title} btn={features.length > 9 &&(!showAllFeatures ? t('show_more_number', { number: numberFeature }) : t('hide_number', { number: numberFeature }))} btnFn={toggleFeatureVisibility}>
      <div className='grid grid-cols-3 md:grid-cols-9 gap-4'>
        {(showAllFeatures ? features : (isMobile ? features.slice(0, 6) : features.slice(0, 9))).map((feature: Feature, index: number) => (
          <div className='flex bg-custom-light-pink justify-center items-center p-1.5 h-9 md:h-12' key={index}>
            <img className='w-auto h-6 md:h-10 mr-2' src={feature.link_immagine || key} alt={`F ${index}`} style={{ filter: 'hue-rotate(130deg) brightness(85%) saturate(78%)' }} />
            <p className='text-xs md:text-sm text-custom-red'>{t(feature.nome)}</p>
          </div>
        ))}
      </div>
    </BaseLayout>
  )
}

export default PropertyFeatures
