export const features_types = {
    'Aria condizionata': 'air_conditioning', 
    'Armadi a muro': 'built_in_wardrobes',
    'Attacco Tv': 'tv_cable',
    'Bio Piscina': 'biopool',
    'Box/Garage': 'box_garage',
    'Camino': 'fireplace',
    'Cantina Vini': 'wine_cellar',
    'Clubhouse': 'clubhouse',
    'Confinante mare':'bordering_sea',
    'Da Ristrutturare': 'to_renovate',
    'Divano Letto': 'sofa_bed',
    'Doccia esterna': 'outdoor_shower',
    'eliporto': 'helipad',
    'Forno a legna': 'wood_oven',
    'Giardino': 'garden',
    'Infinity Pool': 'infinity_pool',
    'Lavastoviglie': 'dishwasher',
    'Lavatrice': 'washing_machine',
    'Molo': 'dock',
    'Orto': 'vegetable_garden',
    'Paese': 'country',
    'Panoramico': 'panoramic',
    'Piano Terra': 'ground_floor',
    'Pieds dans l\'eau': 'waterfront',
    'Piscina condominiale': 'condominium_pool',
    'Pulizia Finale': 'final_cleaning',
    'Ristorante': 'restaurant',
    'Sala Media': 'media_room',
    'Satellitare': 'satellite',
    'Serra': 'greenhouse',
    'Sky': 'sky_tv',
    'Tennis Condominiale': 'condominium_tennis',
    'Terrazza': 'terrace',
    'Terrazza Coperta': 'covered_terrace',
    'Veranda Coperta': 'covered_veranda',
    'Veranda Esterne': 'external_veranda',
    'Vigilanza': 'security',
    'Vista mare': 'sea_view'
};

