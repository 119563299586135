import { useState } from 'react';

// Components
import BaseButton from '../../../base/BaseButton/BaseButton';

// Translations
import { useTranslation } from 'react-i18next';

interface Metric {
  name: string;
  number: number | string;
}

interface TableMetricsProps {
  title: string;
  metrics: Metric[];
  className?: string;
  unit?: string;
  showMoreFn?: () => void;
}

const formatNumber = (number: number | string, addUnit?: string) => {
  const numberStr = String(number);
  if (numberStr.length < 3) {
    return addUnit ? `${numberStr} ${addUnit}` : numberStr;
  }
  const formattedNumber = numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return addUnit ? `${formattedNumber} ${addUnit}` : formattedNumber;
};

const TableMetrics = ({ title, metrics, className = '', unit, showMoreFn }: TableMetricsProps) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = useState(false);

  const tableMetricsClass = `bg-custom-pink px-8 py-4 pt-8 ${className}`;

  const totalMetrics = metrics.length;
  const visibleMetrics = showMore ? totalMetrics : Math.min(totalMetrics, 4);
  const remainingMetrics = totalMetrics - 4;

  const showMoreText = showMore 
    ? t('hide_number', { number: remainingMetrics })
    : t('show_more_number', { number: remainingMetrics });

  if(totalMetrics === 0) return null ;
  else return (
    <div className={tableMetricsClass}>  
      <h1 className='font-playfair text-2xl mb-3'>{t(title)}:</h1>
      <table className='w-full'>
        <tbody>
          {metrics.slice(0, visibleMetrics).map((metric, index) => (
            <tr key={index} className='border-b border-custom-red w-full h-10 text-sm'>
              <td className='text-left w-1/2'>{t(metric.name)}</td>
              <td className='text-left md:text-right md:pr-12 w-1/2 font-medium'>
                {formatNumber(metric.number, unit)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showMoreFn || metrics.length > 4 ? <BaseButton
        dataType='black-underline'
        onClick={showMoreFn? showMoreFn: () => setShowMore(!showMore)}
        text={showMoreFn? t('show_more') : showMoreText}
      /> : null}
    </div>
  );
};

export default TableMetrics;
