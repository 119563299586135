import BaseButton from '../base/BaseButton/BaseButton';
import key from '../../assets/images/key-logo.svg';
import logo from '../../assets/images/red-logo.svg';

const ThankYouMessage = ({ landingData, setOpenModal, setSentMessage, t }) => {
  return (
    <div className="flex flex-col items-center p-12">
      <img src={key} alt="key" className="absolute top-0 left-0 h-full" />
      <img src={logo} alt="logo" className="hidden md:block h-16 z-10" />
      <div className="flex flex-col items-center text-center z-10 w-full">
        <h1 className="font-playfair font-semibold text-5xl my-8">
          {t(landingData.thank_you)}
        </h1>
        <p className="text-xl mb-7 w-5/6">{t(landingData.thank_you_desc)}</p>
      </div>
      <BaseButton
        text="Torna alla home"
        className="w-full justify-center mt-8 z-10"
        onClick={() => {
          setOpenModal(false);
          setSentMessage(false);
        }}
      />
    </div>
  );
};

export default ThankYouMessage;
