import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import BaseLoader from '../../components/base/BaseLoader/BaseLoader';
import BaseModal from '../../components/base/BaseModal/BaseModal';
import CustomSlider from '../../components/Sliders/CustomSlider/CustomSlider';
import FooterWidthButtons from '../../components/footer/FooterWidthButtons';
import HeaderSection from '../../components/HeaderSection/HeaderSection';
import IconGridSection from '../../components/IconGridSection/IconGridSection';
import ImmobilSardaSection from '../../components/ImmobilSardaSection/ImmobilSardaSection';
import ModalForm from '../../components/ModalForm/ModalForm';
import PropertyFooter from '../../components/Property/PropertyFooter/Footer';
import ReservedAreaSection from '../../components/base/ReservedAreaSection/ReservedAreaSection';
import ServicesSection from '../../components/ServicesSection/ServicesSection';
import ThankYouMessage from '../../components/ThankYouMessage/ThankYouMessage';

// Hooks
import useLandingData from '../../hooks/landing-data/landing-data-hook';

// Images & Icons
import arrowRight from '../../assets/icons/arrow-up-right.svg';
import fiftyLogo from '../../assets/images/50logo.svg';
import key from '../../assets/images/key.png';

// Utils & Server
import { generateParams, sendEmail } from '../../server/servers';
import { handleDropdown } from '../../utils/dropdown';

const LandingPage = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [sentMessage, setSentMessage] = useState(false);

  // Custom Hook
  const landingData = useLandingData(i18n); 
  const email = landingData?.formEmail;

  /**
 * Generates the message for the email using the submitted form values.
 * @param {Object} values - The form values.
 * @param {Object} landingData - The landing page data.
 * @returns {string} - The generated message.
 */
const generateMessage = (values, landingData) => {
  const prodottiInteresse = values.select.map(option => option.label).join(', ');
  const link = values.link ? `Link: ${values.link}` : ''; // Add link only if it exists

  // Replace placeholders with real values
  return landingData.email_template
    .replace(/{nome}/g, values.nome)
    .replace(/{cognome}/g, values.cognome)
    .replace(/{email}/g, values.email)
    .replace(/{telefono}/g, values.telefono)
    .replace(/{prodottiInteresse}/g, prodottiInteresse)
    .replace(/{link}/g, link); // Replace with link if present, otherwise empty
};

  /**
   * Handles the submission of the form by generating an email message using
   * the form values and sending it to the specified email address.
   * @param {Object} values - The form values.
   */
  const handleInfo = (values) => {
    const nameSender = values.nome + ' ' + values.cognome;
    const message = generateMessage(values, landingData);
    const params = generateParams(email, 'Informazioni', message, nameSender);
    sendEmail(params);
    setSentMessage(true);
  };

  useEffect(() => {
    handleDropdown(openModal);
  }, [openModal]);

  useEffect(() => {
    if (sentMessage) {
      navigate('?thankYouforRequest=true', { replace: true });
    } else {
      navigate('', { replace: true });
    }
  }, [sentMessage, navigate]);

  if (!landingData) {
    return <BaseLoader loading={true} />;
  }

  return (
    <div className="relative pb-[156px] md:pb-0">
      <section className="h-screen">
        <HeaderSection
          landingData={landingData}
          navigate={navigate}
          handleInfo={() => setOpenModal(true)}
        />
        <CustomSlider
          slides={landingData.slides}
          buttonTxt={landingData.ask_info}
          btnOnClick={() => setOpenModal(!openModal)}
        />
      </section>
      <IconGridSection landingData={landingData} />
      <ServicesSection landingData={landingData} />
      <ReservedAreaSection landingData={landingData} />
      <ImmobilSardaSection
        keyImage={key}
        landingData={landingData}
        arrowRight={arrowRight}
        logo={fiftyLogo}
      />
      <BaseModal
        isForm={!sentMessage}
        open={openModal}
        handleClose={() => {
          setOpenModal(false);
          setSentMessage(false);
        }}
        children={
          !sentMessage ? (
            <ModalForm
              landingData={landingData}
              handleInfo={handleInfo}
              setOpenModal={setOpenModal}
            />
          ) : (
            <ThankYouMessage
              landingData={landingData}
              setOpenModal={setOpenModal}
              setSentMessage={setSentMessage}
              t={t}
            />
          )
        }
      />
      <PropertyFooter homefooter noFixed />
      <FooterWidthButtons
        isLanding
        btnBText={landingData.access_to_reserved_area}
        btnRText={landingData.ask_info}
        btnBlackFn={() => navigate('/home')}
        btnRedFn={() => setOpenModal(true)}
      />
    </div>
  );
};

export default LandingPage;
