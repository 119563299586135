import React from 'react';
import ReactDOM from 'react-dom/client';
// Components
import App from './App';
import reportWebVitals from './reportWebVitals';
// Context
import { AuthProvider } from './contexts/Auth/AuthContext';

// Styles
import './index.css';
import 'tailwindcss/tailwind.css';

// Import i18next initialization promise ;
import { i18nInitPromise } from './i18n';

// Create a render root 
const root = ReactDOM.createRoot( document.getElementById('root') as HTMLElement );

// Render the app only after i18next has been initialized
i18nInitPromise
  .then(() => {
    root.render(
      <React.StrictMode>
        <AuthProvider>
          <App />
        </AuthProvider>
      </React.StrictMode>
    ); 
  })
  .catch(error => {
  console.error('Error initializing i18n:', error);
  // You can handle a rendering error or show an error message here
  });

// If you want to start measuring performance of your app, pass a function
// to log the results (example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals( );