import React, { useEffect, useState } from 'react';

// Components
import BaseModal from '../base/BaseModal/BaseModal';
import PdfViewer from '../PdfPreview/PdfPreview';

// Hooks
import { usePropertyDetails } from '../Property/PropertyDetails/hook/property-details-hook';

// Images
import document from 'feather-icons/dist/icons/file-text.svg'

// Store
import useStore from '../../store/store';

// Utils
import { handleDropdown } from '../../utils/dropdown';

/**
 * Renders a PDF document viewer component that displays a list of PDF documents.
 * 
 * @param {PdfDocumentProps} props - The component props.
 * @param {string[]} [props.documents] - An array of PDF document URLs to display.
 * @param {string} [props.id] - The ID of the property associated with the PDF documents.
 * @returns {JSX.Element} - The rendered PDF document viewer component.
 */
interface PdfDocumentProps {
    documents?: string[];
    id?: string;
}

const PdfDocument: React.FC<PdfDocumentProps> = ({ documents = [], id }) => {
  const [currentPdf, setCurrentPdf] = useState<string>(''); 

    const { getOriginalFileName, handleOpenModal, isModalOpen, handleCloseModal } = usePropertyDetails(String(id), undefined);
  
    const firstLevel = useStore(state => state.firstLevel);

    const handlePreview = (pdfUrl: string) => {
      setCurrentPdf(pdfUrl);
      handleOpenModal();
  };

  useEffect(()=>{
    handleDropdown(isModalOpen);
  },[isModalOpen]);

    return (
      <>
        {documents.map((url, index) => {
          if (!firstLevel) return null;
          return (
            <div key={index} className="flex items-end cursor-pointer">
              <img src={document} alt='pdf' className='w-6 mr-2.5' />
              <h3 className="text-xs md:text-sm underline" onClick={() => handlePreview(String(url))}>
                {getOriginalFileName(String(url))}
              </h3>
              <BaseModal open={isModalOpen} handleClose={handleCloseModal} children={<PdfViewer pdf={currentPdf} />} />
            </div>
          );
        })}
      </>
    );
};

export default PdfDocument;
