import { useState } from 'react';
/**
 * A custom React hook that manages the expansion state of a description component.
 *
 * @returns {Object} An object with two properties:
 *   - `isExpanded`: a boolean indicating whether the description is currently expanded or not.
 *   - `toggleExpansion`: a function that can be called to toggle the expansion state.
 */
export const useDescriptionExpansion = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpansion = () => setIsExpanded(!isExpanded);
  return { isExpanded, toggleExpansion };
};