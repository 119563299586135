// Components
import PropertyHeader from "../Property/PropertyHeader/PropertyHeader";

/**
 * Renders the header section of the application, including a property header and a title.
 *
 * @param {Object} props - The component props.
 * @param {string} props.lineImage - The URL of the line image to be displayed in the background.
 * @param {Object} props.landingData - An object containing data for the landing page, including the access to reserved area text, the ask info text, and the title.
 * @param {function} props.navigate - A function to navigate to the home page.
 * @param {function} props.handleInfo - A function to handle info.
 * @returns {JSX.Element} - The rendered header section.
 */
const HeaderSection = ({  landingData, navigate, handleInfo }) => {
  return (
    <div
      className="w-full h-fit md:h-2/5 relative bg-white">
      <PropertyHeader
        isLanding
        btnBText={landingData.access_to_reserved_area}
        btnRText={landingData.ask_info}
        btnBlackFn={() => navigate('/home')}
        btnRedFn={handleInfo}
      />
      <p className="px-12 pt-12 text-sm md:text-2xl uppercase leading-14" >{landingData.discover_opportunies}
        <span className='font-bold'>{landingData.reserved_for_you}</span>
      </p>
      <h1
        className="font-playfair text-3xl md:text-6xl px-12 py-4 font-medium w-full mb-8"
        // dangerouslySetInnerHTML={{ __html: landingData.title }}
        >{landingData.welcome_to_platform}</h1>
    </div>
  );
};

export default HeaderSection;