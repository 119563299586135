import { useState, useEffect } from 'react';

// Components
import BaseButton from '../../base/BaseButton/BaseButton';

// Icons
import { ReactComponent as arrowLeft } from 'feather-icons/dist/icons/chevron-left.svg';
import { ReactComponent as arrowRight } from 'feather-icons/dist/icons/chevron-right.svg';

// Images
import slide from '../../../assets/images/exampleSlide.jpg';
import slide2 from '../../../assets/images/exampleSlide2.jpg';
import slide3 from '../../../assets/images/exampleSlide3.jpg';

// Styles
import './CustomSlider.css';
import useStore from '../../../store/store';

/**
 * A custom slider component that displays a set of slides with navigation buttons.
 *
 * @param {Object[]} slides - An array of slide objects, each containing a title, description, and image.
 * @param {string} buttonTxt - The text to display on the button.
 * @param {function} btnOnClick - The function to call when the button is clicked.
 * @param {number} [autoPlayInterval=4000] - The interval in milliseconds for the automatic slide transition.
 * @returns {JSX.Element} - The custom slider component.
 */
const CustomSlider = ({ slides, buttonTxt, btnOnClick, autoPlayInterval = 4000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const { setCurrentSlideOption } = useStore(state => state);

 // Associate the images to the slides via the index
  const slideImages = [slide, slide2, slide3];

  /**
   * Handles the transition to the next slide in the custom slider.
   */
  const handleNext = () => {
    setCurrentIndex(prevIndex => (prevIndex + 1) % slides.length);
  };

  /**
   * Handles the transition to the previous slide in the custom slider.
   */
  const handlePrev = () => {
    setCurrentIndex(prevIndex => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
  };

  /**
   * Sets up an interval timer that automatically transitions to the next slide.
   */
  useEffect(() => {
    if (autoPlay) {
      const timer = setInterval(() => {
        handleNext();
      }, autoPlayInterval);
      return () => clearInterval(timer);
    }
    // eslint-disable-next-line
  }, [autoPlay, autoPlayInterval]);

  /**
   * Handles the click event for the main button.
   */
  const handleBtnClick = () => {
    setCurrentSlideOption(slides[currentIndex].title);
    btnOnClick();
  };

  // Retrieves the current slide object from the `slides` array based on the `currentIndex` state.
  const currentSlide = slides[currentIndex];

  return (
    <div className='relative px-12 border-b border-b-black pb-16 h-3/5'>
      <div className='h-full flex justify-between'>
        <BaseButton
          iconComponent={arrowLeft}
          className='absolute left-2 md:left-0 top-1/2 transform -translate-y-1/2'
          dataType='black-text'
          iconClass='h-7 w-7'
          onClick={handlePrev}
        />
  
        <div className='h-full w-full md:w-1/3 flex flex-col md:pr-12 justify-between  '>
          <div>
            <div className='dots-container flex mb-4'>
              {slides.map((_, index) => (
                <div
                  key={index}
                  className={`dot ${index === currentIndex ? 'active' : ''}`}
                  onClick={() => {
                    setAutoPlay(false);
                    setCurrentIndex(index);
                  }}
                ></div>
              ))}
            </div>
    
            <div className='md:hidden mb-4'>
              <img
                src={slideImages[currentIndex]}
                alt={`Slide ${currentIndex + 1}`}
                className='w-full h-auto'
              />
            </div>
    
            <h2 className='font-playfair py-4 text-3xl md:text-4xl text-left'>{currentSlide.title}</h2>
            <p className='font-roboto text-sm'>{currentSlide.description}</p>
          </div>
          <BaseButton className='mt-4 w-full justify-center' text={buttonTxt} onClick={handleBtnClick} />
        </div>
  
        <div className='h-full hidden md:flex col-span-2 w-2/3 pl-8'>
          <img
            src={slideImages[currentIndex]}
            alt={`Slide ${currentIndex + 1}`}
            className='w-full h-full object-cover'
          />
        </div>
  
        <BaseButton
          iconComponent={arrowRight}
          className='absolute right-2 md:right-0 top-1/2 transform -translate-y-1/2'
          iconClass='h-7 w-7'
          dataType='black-text'
          onClick={handleNext}
        />
      </div>
    </div>
  );
};

export default CustomSlider;