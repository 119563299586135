import React from 'react'

// Components
import BaseButton from '../base/BaseButton/BaseButton';

// Hook
import useHome from '../../Views/Home/hook/home-hook';

// Translations
import { useTranslation } from 'react-i18next';
/**
 * A React functional component that renders a section for contacting an agent.
 *
 * @param name - The name of the agent to display.
 * @param redLinks - An array of objects with a name, URL, and icon component for each link to display.
 * @param handleIconClick - A function to handle clicking on one of the link icons.
 * @returns A React element representing the contact agent section.
 */
const ContactAgentSection= () => {
    
    const { t } = useTranslation();
    const { links, handleIconClick, name, redLinks } = useHome();

    return (
      <>
        <h3 className='font-roboto text-sm uppercase pt-8 md:pb-6 font-medium border-t border-black mt-8'>
          {`${t('contact_agent', { name })}, ${t('assistant')}`}
        </h3>
        <div className='flex flex-col md:flex-row justify-between text-white'>
        {redLinks.map((link, index) => (
            <BaseButton
            className={`hidden md:flex justify-center w-full ${index !==0 ? 'md:ml-2': ''}`}
              key={index}
              text={link.name}
              onClick={() => handleIconClick({ url: link.url })}
              iconComponent={link.icon as React.ElementType}
            />
          ))}
          {links.map((link, index) => (
            <a key={index} href={link.url} className=" md:hidden text-black text-[14px] flex items-center border border-[rgba(60, 61, 59, 0.1)] rounded-sm p-2 mb-2">
            {typeof link.icon === 'string' ? (
              <img src={link.icon} alt="wa" className="mr-2 w-[18px] h-[18px]" />
            ) : null}
              <span className=' mr-1'>{t(link.details)}</span>
            </a>
          ))}
        </div>
      </>
    );
  };

export default  ContactAgentSection;