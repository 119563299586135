import React, { useEffect, useState } from 'react';
import { MultiSelect } from "react-multi-select-component";

// Base Components
import BaseButton from '../base/BaseButton/BaseButton';
import BaseInput from '../base/BaseInput/BaseInput';
import DynamicCheckbox from '../base/BaseCheckbox/BaseCheckbox';

// Style
import './GenericForm.css';

// Translations
import { useTranslation } from 'react-i18next';

// Types
import { FormProps } from './types';
import useStore from '../../store/store';

/**
 * Component used to render a form
 */
const GenericForm = <T extends Record<string, any>>({
  inputColor,
  inputErrorColor,
  submit,
  fields,
  onSubmit,
  title,
  checkboxLabel,
}: FormProps<T>) => {

  const { t } = useTranslation();
  const { isChecked } = useStore(state => state); 
  const [values, setValues] = useState<Record<string, any>>(
    fields.reduce((acc, field) => {
      acc[field.id] = field.type === 'select' && field.multiple ? [] : '';
      return acc;
    }, {} as Record<string, any>)
  );
  const [isValid, setIsValid] = useState<boolean>(true);
  const [selected, setSelected] = useState([]);

  /**
   * Handles a change in the form by updating the values state and determining if the form is valid.
   */
  const handleChange = (id: string, value: any) => {
    setValues((prevState) => {
      const newState = {
        ...prevState,
        [id]: value,
      };

      const isValidNow = validateFields(newState);
      if (JSON.stringify(newState) !== JSON.stringify(prevState)) {
        setIsValid(isValidNow);
      }
      return newState;
    });
  };

  /**
   * Validates all fields of the form by calling their respective validation functions.
   */
  const validateFields = (state: Record<string, any>) => {
    return fields.every((field) =>
      field.validationRule ? field.validationRule(state[field.id]) : true
    );
  };

  /**
   * Handles the submission of the form. Prevents the default form submission event, and then calls the provided `onSubmit` function with the current state of the form fields as an argument.
   */
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(values as T);
  };

  // Split the fields into two halves
  const half = Math.ceil(fields.length / 2);
  const leftFields = fields.length <= 2 ? fields.slice(0, 2) : fields.slice(0, half);
  const rightFields = fields.length > 2 ? fields.slice(half) : [];

  const requiredFields = fields.filter((field) => 
    field.id === 'nome' || 
    field.id === 'cognome' || 
    field.id === 'email' || 
    field.id === 'telefono'
  );

  /**
   * Checks if the form is valid and sets the `isValid` state accordingly.
   */
  useEffect(() => {
    // Check if there are any invalid fields
    const hasInvalidFields = fields.some(field => 
      field.validationRule && 
      (values[field.id] === '' || (Array.isArray(values[field.id]) && values[field.id].length === 0))
    );
    // Check if there are too few fields
    const isTooFewFields = fields.length <= 2;
    
    // Set the `isValid` state
    if(isTooFewFields) setIsValid(true);
    else setIsValid(!hasInvalidFields && isChecked);
  }, [fields, values, isChecked]);

  return (
    <div className={`${fields.length > 2 ? 'p-0 ' : 'p-12 md:p-0'}`}>
      <form onSubmit={handleSubmit} className={`${fields.length > 2 ? 'h-responsive-form-modal' : 'h-login-responsive'} w-full md:h-full flex flex-col justify-between md:justify-start items-center`}>
        <div className={`${fields.length <= 2 ? 'flex-col' : 'flex-row'} w-full flex justify-between`}>
          {title && <h1 className='font-playfair text-4xl text-white mb-12'>{title}</h1>}
          <div className={`${rightFields.length > 0 ? 'w-form-responsive' : 'w-full'} md:pr-4`}>
            {leftFields.map((field) => (
              <div key={field.id} className="mb-4">
                <BaseInput
                  color={inputColor}
                  errorRules={field.validationRule}
                  placeholder={field.placeholder}
                  label={field.label}
                  type={field.type}
                  value={values[field.id]}
                  onChange={(e) => handleChange(field.id, e.target.value)}
                  requiredFields={requiredFields.includes(field)}
                  errorColor={inputErrorColor}
                />
              </div>
            ))}
          </div>
          {rightFields.length > 0 && <div className="w-form-responsive font-roboto">
            {rightFields.map((field) => (
              <div key={field.id} className="mb-4">
                {field.type === 'select' ? (
                  <>
                    <label className={`${inputColor} text-sm font-medium`}>{t(field.label)}</label>
                    <MultiSelect
                      options={field.options}
                      value={selected}
                      onChange={(e) => {
                        setSelected(e);
                        handleChange('select', e);
                      }}
                      labelledBy={t(field.placeholder)}
                    />
                  </>
                ) : (
                  <BaseInput
                    color={inputColor}
                    errorColor={inputErrorColor}
                    errorRules={field.validationRule}
                    placeholder={field.placeholder}
                    label={field.label}
                    type={field.type}
                    value={values[field.id]}
                    onChange={(e) => handleChange(field.id, e.target.value)}
                    requiredFields={requiredFields.includes(field)}
                  />
                )}
              </div>
            ))}
          </div>}
        </div>
        {!checkboxLabel ? (
          <div className="flex justify-center mt-4 w-full">
            <BaseButton
              className="w-full justify-center h-10 text-sm"
              dataType="black"
              type="submit"
              text={submit}
              disabled={!isValid}
            />
          </div>
        ) : (
          <div className="flex flex-col md:flex-row justify-between items-center">
            <DynamicCheckbox label={checkboxLabel} />
            <BaseButton
              className="w-64 justify-center"
              type="submit"
              text={submit}
              disabled={!isValid}
            />
          </div>
        )}
      </form>
    </div>
  );
};

export default GenericForm;