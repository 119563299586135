import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import ImageGallery from 'react-image-gallery';

// Base Components
import BaseButton from '../../base/BaseButton/BaseButton';
import BaseLayout from '../../base/BaseLayout/BaseLayout';
import BaseLoader from '../../base/BaseLoader/BaseLoader';
import BaseModal from '../../base/BaseModal/BaseModal';
import BaseSlider from '../../base/BaseSlider/BaseSlider';

// Components
import GoogleMap from '../../GoogleMap/GoogleMap';
import PropertyDescription from '../PropertyDescription/PropertyDescription';
import PropertyDocuments from '../PropertyDocuments/PropertyDocuments';
import PropertyFeatures from '../PropertyFeatures/PropertyFeatures';
import PropertyFooter from '../PropertyFooter/Footer';
import PropertyHeader from '../PropertyHeader/PropertyHeader';
import PropertyImageGallery from '../PropertyImageGallery/PropertyImageGallery';
import SignatureContent from '../../SignatureContent/SignatureContent';

// Hooks
import { usePropertyDetails } from './hook/property-details-hook';

//Icons 
import { ReactComponent as CloseIcon } from 'feather-icons/dist/icons/x.svg'

// Store
import useStore from '../../../store/store';

// CSS per react-image-gallery
import 'react-image-gallery/styles/css/image-gallery.css';
import './ImageGallery.css';

// Translation
import { useTranslation } from 'react-i18next';

// Utils
import { handleDropdown } from '../../../utils/dropdown';
import PropertyCardSlider from '../../PropertyCardSlider/PropertyCardSlider';

/**
 * Renders the detailed information for a specific property.
 * This component provides a comprehensive view of the property's characteristics and amenities, including:
 * - Property header with contact information and navigation
 * - Image slider for property photos and videos
 * - Property description with title, location, and details
 * - Image gallery for property photos and floor plans
 * - Property features and amenities
 * - Property documents and data room access
 * - Linked sub-properties in the same complex
 * - Google Map showing the property's location
 * - Property footer with additional details and actions
 *
 * @returns The rendered PropertyDetails component
 */
const PropertyDetails: React.FC = () => {
  const { t } = useTranslation();
  const { propertyId, subPropertyId } = useParams<{ propertyId: string,  subPropertyId: string }>();
  const navigate = useNavigate();

  const {firstLevel, isGalleryOpen, setIsGalleryOpen, galleryIndex, setGalleryType, allProperties } = useStore();
 
  const { details, isLoading, isModalOpen, handleCloseModal, handleOpenModal, newDetails, propertyLocation, title, allFeatures, pointOfInterest , images, currentId} = usePropertyDetails(propertyId, subPropertyId);

  // Find the parent object inside `allProperties`
  const fatherProperty = allProperties.find((el) => el.immobili_id === newDetails.immobili_id);
    
  // Initialize an array that will contain the parent and/or children
  let newComplexProperties = [];

  if (fatherProperty) {
    // If `fatherProperty` exists, add it to the array
    if(subPropertyId && fatherProperty.immobili_id === propertyId) newComplexProperties.push(fatherProperty);
    
    if (fatherProperty.immobili_elementi && fatherProperty.immobili_elementi.length > 0) {
      newComplexProperties.push(...fatherProperty.immobili_elementi);
    }
  } else if (newDetails.immobili_elementi && newDetails.immobili_elementi.length > 0) {
    // If no `fatherProperty` exists but `newDetails.immobili_elementi` has children, add only those children
    newComplexProperties.push(...newDetails.immobili_elementi);
  }

  useEffect(() => {
    handleDropdown(isModalOpen);
  },[isModalOpen]);

  return (
    <>
      {isLoading ? (
        <BaseLoader loading={isLoading} />
      ) : (
        <div className='pb-16'>
          <PropertyHeader showContact btnClick={() => navigate(-1)} />
          <BaseSlider images={newDetails?.immagini} video={newDetails?.videos} />
          <PropertyDescription details={newDetails} title={title} subtitle={propertyLocation} onClick={handleOpenModal} /> 
          {newDetails.features?.length > 0  && newDetails?.enabled_data_room > 0 ? <PropertyFeatures features={allFeatures} title='features'/>: null}
          <PropertyImageGallery images={newDetails?.immagini} title='photo'/>

         {newDetails?.immagini_planimetria?.length > 0 && <PropertyImageGallery plants={newDetails?.immagini_planimetria} title='floor_plans'/>}

          {newDetails.enabled_data_room > 0 && newDetails.documenti_data_room?.length > 0 && <PropertyDocuments  details={newDetails} id={currentId} />}
          
          { newComplexProperties.length > 0 &&
          <BaseLayout title= {t('complex_house', {type:newDetails.nome_tipologia})}>
            <section className='flex flex-col md:flex-row py-6 w-full mb-4 items-center md:items-start overflow-x-scroll'>
              <PropertyCardSlider properties={newComplexProperties}/>
            </section>
          </BaseLayout>
          }

          <BaseLayout title='where' subtext={!firstLevel? t('request_location'): null}>
           <section className='text-left md:pt-2 md:mt-4' >
              <GoogleMap position={pointOfInterest} property={newDetails} />
            </section>
          </BaseLayout>

          <BaseModal 
            open={isModalOpen} 
            handleClose={handleCloseModal} 
            widthFix='w-[900px]' 
            children={ 
              <SignatureContent subtitle='next_steps' dataroom={newDetails.enabled_data_room} duediligence={newDetails.enabled_due_diligence} />
            }
          />
          {isGalleryOpen && (
                <div className="fullscreen-gallery">
                  <BaseButton dataType='black' className='absolute top-0 right-0 z-[99] m-4' iconComponent={CloseIcon} onClick={() => {setIsGalleryOpen(false); setGalleryType('photos');}} text='close' />
                  <ImageGallery
                      items={images}
                      startIndex={galleryIndex}
                      onScreenChange={(fullScreenElement) => {
                          if (!fullScreenElement) {
                              setIsGalleryOpen(false);
                          }
                      }}
                      showFullscreenButton={false}
                      showPlayButton={false}
                      additionalClass="fullscreen-gallery custom-nav-buttons" 
                  />
                </div>
            )}
          <PropertyFooter 
            property={newDetails}
            dataroom={details.enabled_data_room || 0} 
            duediligence={details.enabled_due_diligence || 0} 
            propertyName={title} 
            mq={newDetails.mq_commerciali || 0} 
            ville={newDetails.estate_num_ville || 0}
            btnFn={handleOpenModal}
          />
        </div>
      )}
    </>
  );
};


export default PropertyDetails;