import React from 'react';

// Icons
import { ReactComponent as NumbersIcon } from '../../assets/icons/numbers.svg';
import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg';
import { ReactComponent as SignatureIcon } from '../../assets/icons/sign.svg';

interface IconWithBorderProps {
  type: 'sign' | 'info' | 'numbers';
  isActive: boolean;
}

const icons = {
  sign: SignatureIcon,
  info: InfoIcon,
  numbers: NumbersIcon,
};

const IconWithBorder: React.FC<IconWithBorderProps> = ({ type, isActive }) => {
  const IconComponent = icons[type];

  if (!IconComponent) return null;

  return (
    <div className={`flex flex-col ${type === 'sign' ? 'items-start' : 'items-center'}`}>
        <IconComponent className={`m-2 h-3.5 md:h-5  ${isActive ? 'text-custom-pink' : 'text-[#f4e9ea80]'}`} />
        <div className={`border ${isActive ? 'border-custom-pink' : 'border-[#f4e9ea80]'} 
            ${type === 'sign' ? 'w-1/2' : 'w-full'} relative mb-2`}
        >
        <div className={`border-2 rounded-full 
            ${isActive ? 'border-custom-pink bg-custom-pink' : 'border-[#f4e9ea80] bg-custom-red'} absolute bottom-[-3px] ${type === 'sign' ? 'left-[100%]' : 'left-1/2'} w-2 h-2`} />
        </div>
    </div>
  );
};

export default IconWithBorder;
