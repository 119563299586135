import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';

// Store
import useAuthStore from '../store/auth';

// Define the props for the ProtectedRoute component. It expects an element, which is the component to render if the user is authenticated
interface ProtectedRouteProps {
  element: React.ReactElement;
}

// ProtectedRoute component checks if the user is authenticated before rendering the protected content
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
  const { isAuthenticated } = useAuthStore();

  useEffect(() => {
    const token = localStorage.getItem('token');
    const userDetails = localStorage.getItem('userDetails');
    if (token && userDetails) {
      useAuthStore.setState({ isAuthenticated: true, token, userDetails: JSON.parse(userDetails) });
    }
  }, []);

  // If authenticated, render the protected content; otherwise, redirect to the login page
  return isAuthenticated ? element : <Navigate to="/login" replace />;
};

export default ProtectedRoute;
