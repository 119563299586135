import React from 'react';

// Types
import { MediaSlideProps } from '../../../types/slider-type';

/**
 * Renders a media slide component based on the provided media item, current index, video reference, and callback functions.
 */
const MediaSlide: React.FC<MediaSlideProps> = ({ 
  mediaItem, 
  currentIndex, 
  videoRef, 
  onVideoEnd, 
  setIsPlaying, 
  setIsLoading, 
  onImageClick 
}) => {
  return (
    <div className="flex overflow-hidden items-start bg-gradient-top-bottom-gray h-video">
      {mediaItem.type === 'video' && currentIndex === 0 ? (
        <video
          ref={videoRef}
          src={mediaItem.link}
          className="w-full h-full object-cover opacity-85"
          autoPlay
          onPlay={() => {
            setIsLoading(false); 
            setIsPlaying(true);
          }}
          onPause={() => setIsPlaying(false)}
          onEnded={onVideoEnd}
          onLoadedData={() => setIsLoading(false)}  
          onWaiting={() => {
            setIsLoading(true);
          }}
          muted
        />
      ) : mediaItem.type === 'image' ? (
        <img
          src={mediaItem.link}
          alt={`Slide`}
          className="w-full h-full object-cover opacity-85 cursor-pointer"
          onLoad={() => setIsLoading(false)} 
          onClick={() => onImageClick()}
        />
      ) : null}
    </div>
  );
};

export default MediaSlide;
