import React, { useRef, useState, useEffect } from 'react';

// Components
import BaseLayout from '../../base/BaseLayout/BaseLayout';
import CustomLoadingBar from '../../base/BaseLoading/BaseLoadingBar';

// Hooks
import useScrollableBehavior from '../../../hooks/scrollable-hook/scrollable-hook';

// Icons
import chevronLeft from 'feather-icons/dist/icons/chevron-left.svg';
import chevronRight from 'feather-icons/dist/icons/chevron-right.svg';

// Store
import useStore from '../../../store/store';

// Types
import { PropertyImageGalleryProps } from '../types/types';

const PropertyImageGallery: React.FC<PropertyImageGalleryProps> = ({ images, title, plants }) => {
  const arrayToMap = images ? images : plants;
  const galleryRef = useRef<HTMLDivElement>(null);
  
  const [isScrollable, setIsScrollable] = useState(false);
  const { setGalleryIndex, setIsGalleryOpen, setGalleryType } = useStore();

  // Use the custom hook
  const { progress } = useScrollableBehavior(galleryRef.current);

  useEffect(() => {
    const checkScrollable = () => {
      if (galleryRef.current) {
        const { scrollWidth, clientWidth } = galleryRef.current;
        const scrollable = scrollWidth > clientWidth;
        setIsScrollable(scrollable);
      }
    };
    checkScrollable();
    window.addEventListener('resize', checkScrollable);

    return () => window.removeEventListener('resize', checkScrollable);
  }, [arrayToMap]);

  const scrollLeft = () => {
    if (galleryRef.current) {
      galleryRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (galleryRef.current) {
      galleryRef.current.scrollBy({ left: 200, behavior: 'smooth' });
    }
  };

  const barStyle: React.CSSProperties = {
    position: 'absolute',
    left: 0,
    width: `${isScrollable ? progress : 100}%`,
    height: '4px',
    backgroundColor: '#000000',
    transition: 'width 0.2s ease-in-out',
    zIndex: 1,
    bottom: '-4px',
  };

  return (
    <BaseLayout title={title}>
      <CustomLoadingBar barStyle={barStyle} />

      <div className="relative flex items-center">
        {isScrollable && (
          <button
            onClick={scrollLeft}
            className="absolute left-[-27px] z-10 bg-white opacity-75 hover:opacity-100 p-px rounded-full shadow-md"
          >
            <img src={chevronLeft} alt="left" className='h-5'/>
          </button>
        )}

        <div
          ref={galleryRef}
          className="flex overflow-x-auto whitespace-nowrap hide-scrollbar"
        >
          {arrayToMap?.map((image, index) => (
            <img
              key={index}
              className="w-auto h-[208px] my-2 mr-7 cursor-pointer"
              src={plants ? image : image.link_immagine}
              alt={`Feature ${index}`}
              onClick={() => {
                setGalleryType(images ? 'photos' : 'plants');
                setGalleryIndex(index);
                setIsGalleryOpen(true);
              }}
            />
          ))}
        </div>

        {isScrollable && (
          <button
            onClick={scrollRight}
            className="absolute right-[-27px] z-10 p-px bg-white opacity-75 hover:opacity-100 rounded-full shadow-md"
          >
             <img src={chevronRight} alt="right" className='h-5' />
          </button>
        )}
      </div>
    </BaseLayout>
  );
};

export default PropertyImageGallery;
