import React from 'react';

// Components
import BaseButton from '../../base/BaseButton/BaseButton';

// Icons
import { ReactComponent as camera } from 'feather-icons/dist/icons/camera.svg';
import { ReactComponent as play } from 'feather-icons/dist/icons/youtube.svg';
import { ReactComponent as RightIcon } from '../../../assets/icons/chevron-right.svg';
import { ReactComponent as LeftIcon } from '../../../assets/icons/chevron-left.svg';

// Slick
import Slider from 'react-slick';

// Translation
import { useTranslation } from 'react-i18next';

interface ControlsProps {
  sliderRef: React.RefObject<Slider>;
  stopVideoPlayback: () => void;
  handleModal: (type: string | undefined) => void;
  images: Array<{ link_immagine: string }>;
  video?: string[];
  currentIndex: number;
  combinedMediaLength: number;
}

/**
 * Renders the controls for a slider component.
 */
const Controls: React.FC<ControlsProps> = ({ sliderRef, stopVideoPlayback, handleModal, images, video, currentIndex, combinedMediaLength }) => {
    const { t } = useTranslation();
  return (
    <>
      <div className={`absolute hidden md:flex bottom-2 right-2 md:bottom-4 md:right-4 items-center`}>
        <BaseButton 
          className="mr-4 z-[9]" 
          dataType="black" 
          iconComponent={camera} 
          text={t('photos', { n: images.length })} 
          onClick={() => { 
            handleModal('photos');
            stopVideoPlayback(); 
          }} 
        />
        {video && video.length > 0 && (
          <BaseButton 
            className="mr-4 z-[9]" 
            dataType="black" 
            iconComponent={play} 
            text={t('videos', { n: video.length })} 
            onClick={() => { 
              handleModal('videos'); 
              stopVideoPlayback(); 
            }} 
          />
        )}
      </div>

      <div className={`flex absolute bg-black md:bg-transparent bottom-2 md:bottom-4 right-2 md:left-4 items-center text-white py-2 px-4`}>
        <LeftIcon className="hidden md:block mx-2" onClick={() => { sliderRef.current?.slickPrev(); stopVideoPlayback(); }} />
        <span>{currentIndex + 1}/{combinedMediaLength}</span>
        <RightIcon className="hidden md:block mx-2" onClick={() => { sliderRef.current?.slickNext(); stopVideoPlayback(); }} />
      </div>
    </>
  );
};

export default Controls;
