// Base Components
import BaseLoadingBar from '../BaseLoading/BaseLoadingBar';
import SliderWithDescription from '../../Sliders/SliderWithDescription/SliderWidthDescription';

// Images
import slide from '../../../assets/images/info.png';
import slide2 from '../../../assets/images/info_1.png';
import slide3 from '../../../assets/images/info_2.png';
import slide4 from '../../../assets/images/info_3.png';
import slide5 from '../../../assets/images/info_4.png';

// Icons
import { ReactComponent as NumbersIcon } from '../../../assets/icons/numbers.svg';
import { ReactComponent as InfoIcon } from '../../../assets/icons/info.svg';
import { ReactComponent as SignatureIcon } from '../../../assets/icons/sign.svg';

// Store
import useStore from '../../../store/store';

// Translations
import { useTranslation } from 'react-i18next';
import SliderDescription from '../../Sliders/SliderWithDescription/SliderDescription/SliderDescriptions';

type IconComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

/**
 * Renders the ReservedAreaSection component, which displays a section with a title, a custom slider, and a loading bar.
 *
 * @param {object} landingData - An object containing data for the landing page, including the "how_works" title and "ask_info" button text.
 * @returns {JSX.Element} - The rendered ReservedAreaSection component.
 */
export default function ReservedAreaSection({ landingData }) {
  
  const { t } = useTranslation();
  const progress = useStore(state => state.progress);
  const isMobile = window.innerWidth <= 600

  const slides = [
    { img: slide, desc: 'request_access' },
    { img: slide2, title: 'info', desc: 'viewing_basic_info', icon: 'info' },
    { img: slide3, title: 'data_room', desc: "data_room_access", icon: 'numbers' },
    { img: slide4, title: 'due_diligence', desc: "due_diligence_access", icon: 'signature' },
    { img: slide5, desc: 'signature_finalization' },
  ];

  const barStyle: React.CSSProperties = {
    position: 'absolute' as const,
    bottom: 0,
    left: 0,
    width: `${progress}%`,
    height: '4px',
    backgroundColor: '#000000',
    transition: 'width 0.2s ease-in-out',
    zIndex: 1,
  };

  const iconComponents: Record<string, IconComponent> = {
    numbers: NumbersIcon,
    info: InfoIcon,
    signature: SignatureIcon,
  };
  
  return (
    <div className="pb-4 md:border-b md:border-black relative mt-2 mb-8 mx-12 md:m-12">
      <h3 className='text-4xl font-playfair'>{landingData.how_works}</h3>
      {!isMobile ?
        <>
          <SliderWithDescription slides={slides} data={landingData}  iconComponents={iconComponents}/>
          <BaseLoadingBar barStyle={barStyle} /> 
        </>
      :
      slides.map((slide, index) => (
        <div>
           <SliderDescription
              index={index}
              title={slide.title ? t(slide.title) : undefined}
              icon={slide.icon}
              iconComponents={iconComponents}
            />
          <p className='mb-2 text-sm'>{t(landingData[slide.desc])}</p>
          <img
            key={index}
            src={slide.img}
            alt={slide.title}
            className="w-full h-full object-cover"
          />
       </div>
      ))}
    </div>
  );
}