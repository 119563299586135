import { create } from 'zustand';

// Cognito
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

// Data
import Pool from '../data/userPool';

// Types
import { LoginValues, AuthState, } from './types/types';

// Create the Zustand store with the defined state
const useAuthStore = create<AuthState>((set) => ({
  isAuthenticated: !!localStorage.getItem('token'),
  token: localStorage.getItem('token'),
  userDetails: JSON.parse(localStorage.getItem('userDetails') || '{}'),
  error: null,
  loading: false,

 /**
  * Method to authenticate the user
  * @param values - username e password
  */
  authenticate: async (values: LoginValues) => {
    const { email, password } = values;
    const cognitoUser = new CognitoUser({ Username: email, Pool });
    const authenticationDetails = new AuthenticationDetails({ Username: email, Password: password });

    set({ loading: true, error: null });

    try {
      await new Promise<void>((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (authdata) => {
            const cognitoToken = authdata.getAccessToken().getJwtToken();
            const dataInfo = authdata.getIdToken().payload;
            const profileString = dataInfo.profile;
            const parsedProfile = JSON.parse(profileString);
          
            const newUserDetails = {
              time: dataInfo['custom:expiration'],
              name: dataInfo.given_name,
              surname: dataInfo.family_name,
              opportunityId: dataInfo['custom:opportunity_id'],
              role: dataInfo['custom:role'],
              email: dataInfo['cognito:username'],
              assistant: parsedProfile, 
            };

            localStorage.setItem('token', cognitoToken);
            localStorage.setItem('userDetails', JSON.stringify(newUserDetails));
            set({ isAuthenticated: true, token: cognitoToken, userDetails: newUserDetails });
             window.location.href = '/home';
            resolve();
          },
          onFailure: (err) => {
            set({ error: err.message, loading: false });
            reject(err);
          },
        });
      });
    } catch (error: any) {
      set({ error: error.message, loading: false });
    }
  },

  /**
  * funtion to log out the user
  */
  logout: () => {
    const user = Pool.getCurrentUser();
    if (user) user.signOut();
    localStorage.removeItem('token');
    localStorage.removeItem('userDetails');
    set({ isAuthenticated: false, token: null, userDetails: { opportunityId:''} });
  },
}));



export default useAuthStore;
