import propertiesApi from "../api/modules/properties";

import { RoomDetails, CombinedDetails, PropertyDetails, DueDiligenceDocument, GetPropertyTypeProps } from "../types/utils";
/**
 * Method used to retrieve detailed information about a specific property by its ID, including its sub-properties and due diligence documents.
 * The first argument passed to `getPropertyDetails` is intentionally set to null, indicating that no additional parameters are required for this call.
 * @param {string | number} id - The unique identifier (ID) of the property for which details are to be retrieved.
 * @param {string | null} language - The language in which the description should be returned ('it' for Italian, 'en' for English).
 * @param {string | number} opportunityId - The unique identifier (ID) of the opportunity associated with the property.
 * @returns {Promise<Object>} A promise that resolves to an object containing the combined details of the property, including descriptions and videos.
 */
export const getDetails = async (
  id: string | number,
  language: string | null,
  opportunityId: string | number
): Promise<CombinedDetails> => {
  // Retrieve the initial details of the property
  const initialPropertyDetails: PropertyDetails = await propertiesApi.getPropertyDetails(opportunityId, id);
  let roomDetails: RoomDetails = {}; // Initialize roomDetails to hold sub-property details
  let dueDiligenceDocuments: DueDiligenceDocument[] = []; // Initialize dueDiligenceDocuments to hold due diligence documents

  // If there are enabled data rooms for the property
  if (initialPropertyDetails.enabled_data_room > 0) {
    // Fetch the details of the data room
    roomDetails = await propertiesApi.getPropertyDataRoom(opportunityId, id);

    // Update roomDetails with sub-property details
    const updatedRoomDetailsImmobiliElementi = await Promise.all(
      roomDetails.immobili_elementi?.map(async (el) => {
        // Fetch sub-details for each sub-property
        const subdetails = await propertiesApi.getSubpropertyDetails(el.id, 'it');
        const { briefDescription, fullDescription } = determineDescriptions(subdetails, language);

        // Update the subdetail with the given descriptions
        el.descrizione_web_breve = briefDescription; // eslint-disable-next-line
        el. descrizione_web_estesa = fullDescription;

        return {...el, ...subdetails}; // Return the updated element
      }) || []
    );

    // Assign the updated sub-property details to roomDetails
    roomDetails.immobili_elementi = updatedRoomDetailsImmobiliElementi;

    // Fetch due diligence documents for the property
    dueDiligenceDocuments = await propertiesApi.getPropertyDueDiligence(opportunityId, id);
  }

  // Combine all details into a single object
  const combinedDetails: CombinedDetails = {
    ...initialPropertyDetails,
    ...roomDetails,
    ...dueDiligenceDocuments,
  };

  // Extract video links from the combined details
  let videos: string[] = [];
  for (const key in combinedDetails) {
    if (/^link_video_\d+$/.test(key) && combinedDetails[key].trim() !== '') {
      videos.push(combinedDetails[key]);
    }
  }

  // Determine the brief and full descriptions based on the specified language
  const { briefDescription, fullDescription } = determineDescriptions(combinedDetails, language);

  // Ensure updatedDetails includes the determined descriptions and videos
  const updatedDetails: CombinedDetails = {
    ...combinedDetails,
    descrizione_web_breve: briefDescription,
    descrizione_web_estesa: fullDescription,
    videos,
  };

  // Return the final, formatted details
  return updatedDetails;
};

/**
 * Method used to to decode HTML entities in a given string.Converting strings that contain HTML-encoded characters back to their original form.
 * 
 * @param {string} html - The string containing HTML entities to be decoded.
 * @returns {string} The decoded string with HTML entities converted back to their original characters.
 */
export const decodeHtmlEntities = (html: string): string => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
};

/**
 * Determines the brief and full descriptions for a given detail based on the specified language.
 * 
 * @param {Object} detail - The detail object containing the descriptions in different languages.
 * @param {string} language - The language in which the description should be returned ('it' for Italian, 'en' for English).
 * @returns {Object} An object containing the brief and full descriptions determined based on the language.
 */
function determineDescriptions(
  detail: any,
  language: string
): { briefDescription: string, fullDescription: string } {

  let briefDescriptionText = '';
  let fullDescriptionText = '';

  // If the language is Italian
  if (language === 'it') {
    briefDescriptionText = detail.descrizione_web_breve_it || '';
    fullDescriptionText = detail.descrizione_web_estesa_it || '';
  // If the language is English
  } else if (language === 'en') {
    briefDescriptionText = detail.descrizione_web_breve_en || '';
    fullDescriptionText = detail.descrizione_web_estesa_en || '';
  }
  // If the brief description is not available in the specified language, use the default Italian description
  return { briefDescription: briefDescriptionText, fullDescription: fullDescriptionText };
}

/**
 * Method used to determine the property type based on the keywords defined in the types object.
 * 
 * @param {Object} property - The property object containing the property type.
 * @param {string} typeKey - The property type key in the property object.
 * @param {Object} types - The object containing the keywords for each property type.
 * @returns {string} The property type determined based on the keywords.
 */

export const getPropertyType = ({ property, typeKey, types }: GetPropertyTypeProps): string | null => {
  const keywords = Object.values(types);

  // Helper function used to clean and lower case a string
  function cleanAndLower(str: string): string {
    return str.toLowerCase().replace(/[^a-z\s]/gi, "").trim();
  }

  // Return null if the property type is not defined
  if (!property?.[typeKey]) return null;
  
  // Clean and lower case the property type 
  let cleanedType = cleanAndLower(property[typeKey]);

  // Loop through each keyword and check if the cleaned type includes it
  for (let keyword of keywords) {
    if (cleanedType.includes(keyword)) {
      return keyword;
    }
  }

  return null;
};
