import React from 'react';
import Slider from 'react-slick';

// Components
import BaseModal from '../BaseModal/BaseModal';
import Controls from '../../Sliders/SliderControls/SliderControls';
import LoadingSpinner from '../../Sliders/LoadingSpinner/LoadingSpinner';
import MediaSlide from '../../Sliders/MediaSlide/MediaSlide';
import SliderSelection from '../../Sliders/SliderSelection/SliderSelection';

// Hooks
import useSlider from '../../../hooks/slider-hook/slider-hook';

// Types
import { SliderComponentProps } from '../../../types/slider-type';
import useStore from '../../../store/store';

const BaseSlider: React.FC<SliderComponentProps> = ({ video, images }) => {
  const {
    currentIndex,
    type,
    isPlaying,
    isLoading,
    isModalOpen,
    combinedMedia,
    sliderRef,
    videoRef,
    handleModal,
    handleVideoEnd,
    stopVideoPlayback,
    settings
  } = useSlider({ video, images });

  const {setIsGalleryOpen,setGalleryIndex } = useStore();
  const isTablet = window.innerWidth <= 1080;
  const modalWidth = isTablet ? 'w-[90%]' : 'w-[1248px]';

  /**
   * Handles the click event for the image at the current index.
   * If the current index is a video, it goes to the previous image.
   * If the current index is an image, it goes to the same image.
   * It also sets the isGalleryOpen state to true.
   */
  const handleImageAtClick =()=>{
      const videoItem = combinedMedia.find(media => media.type === 'video');
      const correctIndex = videoItem ? currentIndex - 1 : currentIndex;
    
      setGalleryIndex(correctIndex);
      setIsGalleryOpen(true);
  }

  return (
    <div className="relative text-left overflow-hidden h-video z-50">
      <Slider ref={sliderRef} {...settings} className="h-full">
        {combinedMedia.map((mediaItem, index) => (
          <MediaSlide
            key={index}
            mediaItem={mediaItem}
            currentIndex={currentIndex}
            videoRef={videoRef}
            onVideoEnd={handleVideoEnd}
            setIsPlaying={(value: boolean) => isPlaying}
            setIsLoading={(value: boolean) => isLoading}
            onImageClick = {handleImageAtClick}
          />
        ))}
      </Slider>
      <Controls
        sliderRef={sliderRef}
        stopVideoPlayback={stopVideoPlayback}
        handleModal={handleModal}
        images={images}
        video={video}
        currentIndex={currentIndex}
        combinedMediaLength={combinedMedia.length}
      />
      <BaseModal 
        open={isModalOpen} 
        handleClose={() => handleModal(undefined)} 
        children={<SliderSelection videos={video} pictures={images} type={type} />} widthFix={modalWidth}
      />
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default BaseSlider;
