import React from 'react';
import { useTranslation } from 'react-i18next';

interface AssistantInfoProps {
  assistant: {
    given_name: string;
    family_name: string;
  };
  links: {
    url: string;
    icon: string;
    name: string;
    details: string;
    contact: string;
  }[];
  time?: string;
  className?: string;
}

/**
 * Component that renders a section with assistant information.
 * The component renders a section with the assistant's name and contact information.
 * The section is divided into two parts: the first part displays the assistant's name,
 * and the second part displays the assistant's contact information.
 */
const AssistantInfo: React.FC<AssistantInfoProps> = ({ assistant, links, time, className }) => {
  const { t } = useTranslation();

  const isMobile = window.innerWidth <= 600;

  return (
    <div className={`${className} w-full flex flex-col items-start mt-4 md:mt-0`}>
      <div className="p-4 bg-white w-full">
        <div className="flex mb-2 items-center">
          <div>
            <p className="text-sm mb-2">{t('assistant')}</p>
            <p className="text-2xl font-playfair uppercase font-medium">
              {`${assistant.given_name} ${assistant.family_name}`}
            </p>
          </div>
        </div>
        <div className="flex flex-col">
          <p className="text-sm my-2">{t('can_contact_agent')}</p>
          {links.map((link, index) => (
            <div
              key={index}
              className="text-black text-[14px] flex items-center border border-[rgba(60, 61, 59, 0.1)] rounded-sm p-2 mb-2 cursor-pointer"
              onClick={() => window.location.href = link.url} 
            >
              <img
                src={link.icon}
                alt={link.name}
                className="mr-2 w-[18px] h-[18px]"
              />
              <div>
                <p className="mr-1">{t(link.details)}</p>
                {!isMobile && <p className="mr-1 text-[10px]">{link.contact}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AssistantInfo;
