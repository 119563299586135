// Import SDK from AWS
const { SESClient, SendEmailCommand } = require('@aws-sdk/client-ses');

// Configuration
const CONFIG = {
    credentials: {
      accessKeyId: process.env.REACT_APP_SES_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_SES_SECRET_ACCESS_KEY,
    },
    region: 'eu-west-1',
  };

const client = new SESClient(CONFIG);

export const generateParams = (toAddress, subject, body,name) => {
    return {
        Destination: {
            ToAddresses: [toAddress], // Recipient email
        },
        Message: {
            Body: {
                Text: {
                    Charset: 'UTF-8',
                    Data: body, // The body of the email
                },
            },
            Subject: {
                Charset: 'UTF-8',
                Data: subject, // Email subject
            },
        },
        Source: `${name} <noreply@imsmilano.it>`, // Your email address verified on SES `ImsMilano <noreply@imsmilano.com>`
    };
};

// Function to send email
export const sendEmail = async (params) => {
  try {
    const command = new SendEmailCommand(params);
    await client.send(command);
    // console.log('Email inviata con successo!', data.MessageId);
  } catch (error) {
    console.error('Errore nell\'invio dell\'email:', error.message);
  }
};