import React from 'react';

// IconComponent type
type IconComponent = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

interface SliderDescriptionProps {
  index: number;
  title?: string;
  icon?: string;
  iconComponents: Record<string, IconComponent>;
}

const SliderDescription: React.FC<SliderDescriptionProps> = ({ index, title, icon, iconComponents }) => {
  return (
    <div className='flex items-center mb-2 md:mb-8 mt-12'>
      <h1 className='w-8 h-auto rounded-full bg-custom-red text-white text-2xl flex justify-center items-center'>
        {index + 1}
      </h1>
      {title && (
        <div className='ml-4 flex items-center py-2 px-4 bg-custom-pink rounded-3xl text-custom-red w-fit'>
          {icon && iconComponents[icon] && (
            <span className='text-custom-red w-5 h-5 fill-current mr-2'>
              {React.createElement(iconComponents[icon])}
            </span>
          )}
          <p className='font-bold text-xs font-roboto'>{title}</p>
        </div>
      )}
    </div>
  );
};

export default SliderDescription;
