import React from 'react';

// Styles
import './LoadingSpinner.css';

/**
 * Renders a loading spinner component.
 */
const LoadingSpinner: React.FC = () => {
  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="loader"></div>
    </div>
  );
};

export default LoadingSpinner;
