//Images
import directions from '../../assets/images/directions.png';
import security from '../../assets/images/security.png';
import solving from '../../assets/images/solving.png';

/**
 * Renders a service item component with a title and description.
 * @param title - The title of the service item.
 * @param description - The description of the service item.
 * @returns A React component that displays a service item.
 */
const ServiceItem = ({ title, description, img }) => (
    <div className='flex flex-row md:flex-col items-center md:items-start pt-12 md:px-24 md:py-10'>
      <img className='max-h-10 md:max-h-20 pb-0 md:pb-8' src={img} alt={title} />
      <hr className='hidden md:block border-black w-full'/>
      <h4 className='text-xl md:text-2xl font-normal ml-4 md:ml-0 md:py-4 md:py-8'>{title}</h4>
    </div>
  );
  
  /**
   * Renders a section that displays a list of services with titles and descriptions.
   *
   * @param landingData - An object containing data for the services to be displayed, including the service titles and descriptions.
   * @returns A React component that renders the services section.
   */
  const ServicesSection = ({ landingData }) => {

    const services = [
      { title: landingData.needed, description: landingData.needed_description, img: security },
      { title: landingData.administrative_issues, description: landingData.administrative_issues_description, img: solving },
      { title: landingData.remotely_decisons, description: landingData.remotely_decisons_description, img: directions },
    ];

    return (
      <>
        <h3 className="text-3xl md:text-4xl font-playfair pt-4 px-12">{landingData.services}</h3>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 bg-white h-fit relative font-roboto p-12 pt-0 md:p-0">
          {services.map((service, index) => (
            <div  key={`${index}_${service.title}`}>
              <ServiceItem title={service.title} description={service.description} img={service.img}/>
              <hr className='md:hidden border-black w-full'/>
            </div>
          ))}
        </div>
      </>
    );
  };
  
  export default ServicesSection;
  