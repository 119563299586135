import { useState } from 'react';

// Components
import BaseButton from '../../base/BaseButton/BaseButton';

// Hook
import useFullScreenImage from '../../../hooks/full-screen-image-hook';

// Icons
import PlayIcon from 'feather-icons/dist/icons/play-circle.svg';

// Store
import useStore from '../../../store/store';

// Translations
import { useTranslation } from 'react-i18next';

/**
 * Renders a slider selection component that displays either videos or photos based on the selected button.
 *
 * @param {Object} props - The component props.
 * @param {Array<string>} props.videos - An array of video URLs.
 * @param {Array<{ link_immagine: string }>} props.pictures - An array of objects containing image URLs.
 * @param {string} props.type - The initial selected button type ('videos' or 'photos').
 * @returns {JSX.Element} - The rendered slider selection component.
 */
const SliderSelection = ({ videos, pictures, type='photos' }) => {
    const { t } = useTranslation();
    const [selectedBtn, setSelectedBtn] = useState(type);
    const { setGalleryIndex, setIsGalleryOpen, isModalOpen, setIsModalOpen, setGalleryType } = useStore();
    const { handleMediaClick } = useFullScreenImage();

    /**
     * Handles the click event for the button that selects the 'videos' or 'photos' view.
     *
     * @param {string} btnType - The type of button that was clicked ('videos' or 'photos').
     * @returns {void}
     */
    const handleButtonClick = (btnType) => setSelectedBtn(btnType);

    /**
     * Handles the click event for an image in the 'photos' view.
     *
     * @param {number} index - The index of the clicked image in the `pictures` array.
     * @returns {void}
     */
    const handleImageClick = (index) => {
    if (isModalOpen) setIsModalOpen(false);
    setGalleryType('photos');
    setGalleryIndex(index);
    setIsGalleryOpen(true);
    };

  return (
    <>
      <div className="flex justify-center">
        {videos?.length > 0 && (
          <BaseButton
            onClick={() => handleButtonClick('videos')}
            className="mr-4"
            dataType={selectedBtn === 'videos' ? 'default' : 'red-text-line'}
            text={t('videos', { n: videos?.length })}
          />
        )}
        <BaseButton
          onClick={() => handleButtonClick('photos')}
          className="mr-4"
          dataType={selectedBtn === 'photos' ? 'default' : 'red-text-line'}
          text={t('photos', { n: pictures?.length })}
        />
      </div>
      <hr className="my-3" />
      <div className={selectedBtn === 'videos' && videos.length === 1 ? '' : `grid grid-cols-3 gap-2`}>
        {selectedBtn === 'photos'
          ? pictures?.map((pic, index) => (
              <div
                key={index}
                className="col-span-1 bg-cover bg-center w-slider-content h-20 md:h-40 m-2 cursor-pointer"
                style={{ backgroundImage: `url(${pic.link_immagine})` }}
                onClick={() => handleImageClick(index)}
              />
            ))
          : videos?.map((video, index) => (
              <div key={index} className="relative col-span-1 border border-gray-200 cursor-pointer">
                <video src={video} className="w-full h-auto" />
                <div
                  className="absolute inset-0 flex items-center justify-center"
                  onClick={() => handleMediaClick('video', video)}
                >
                  <div className="w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <img src={PlayIcon} alt="play" className="w-16 h-16 text-white invert" />
                  </div>
                </div>
              </div>
            ))}
      </div>
    </>
  );
};

export default SliderSelection;
