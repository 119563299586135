import instance from '../index';
import awsInstance from './indexSubporperty';

// Declare GetImmobili and GetDettaglio as both types and values
const GetImmobili = 'getImmobiliLista.php';
const GetDettaglio = 'getImmobileDettaglio.php';
const GetImmobilFigli = 'getImmobiliFigli.php';
const GetDataRoom = 'getImmobileDataRoom.php';
const GetDueDiligent = 'getImmobileDueDiligence.php';
const GetInfoImmobile ='getInfoImmobile';

// Updated interface to include id parameter for getPropertyDetails
interface PropertiesApi {
    getProperties(id: any): Promise<any>;
    getPropertyDetails(id: any, propertyId: any): Promise<any>; 
    getChildrenProperties(id: any): Promise<any>;
    getPropertyDataRoom(id: any, propertyId: any): Promise<any>; 
    getPropertyDueDiligence(id: any, propertyId: any): Promise<any>; 
    getSubpropertyDetails(id:any, language: any): Promise<any>; 
}
  
  const propertiesApi: PropertiesApi = {
    getProperties(id) {
      const params = {opportunita_id: id};
      return instance.get(GetImmobili, { params });
    },
    getPropertyDetails(id, propertyId) { 
      const params = {
        opportunita_id: id,
        immobile_id: propertyId
      };
      return instance.get(GetDettaglio, { params });
    },
  getChildrenProperties(id){
      const params = {
        immobile_id: id
      };
      return instance.get(GetImmobilFigli, { params }); 
    },
    getPropertyDataRoom(id, propertyId){
      const params = {
        opportunita_id: id,
        immobile_id: propertyId
      };
      return instance.get(GetDataRoom, { params }); 
    },
    getPropertyDueDiligence(id, propertyId){
      const params = {
        opportunita_id: id,
        immobile_id: propertyId
      };
      return instance.get(GetDueDiligent, { params }); 
    },
    getSubpropertyDetails(id, language){
      const url = `${GetInfoImmobile}/${id}/${language}`;
      return awsInstance.get(url); 
  }
  };
  
  export default propertiesApi;