import React from 'react';

// Styles
import './PdfPreview.css';

// Types
import { PdfViewerProps } from '../Property/types/types';
import useStore from '../../store/store';

/**
 * A React component designed to display PDF documents within a web application.
 * It utilizes the `react-pdf` library to render PDF files efficiently.
 */
const PdfViewer: React.FC<PdfViewerProps> = ({ pdf }) => {
  const secondLevel = useStore(state => state.secondLevel);

  return (
    <div className="pdf-viewer-container">
        <embed
          src={pdf}
          type="application/pdf"
          className={!secondLevel ? 'no-print' : ''}
        />
    </div>
  );
};

export default PdfViewer;
