import { useState, useEffect } from 'react';

interface UseScrollableBehaviorReturn {
  progress: number;
}

const useScrollableBehavior = (scrollElement: HTMLElement): UseScrollableBehaviorReturn => {
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    if (!scrollElement) return;

    const handleScroll = () => {
      const scrollLeft = scrollElement.scrollLeft;
      const maxScrollLeft = scrollElement.scrollWidth - scrollElement.clientWidth;
      const scrollPercentage = (scrollLeft / maxScrollLeft) * 100;
      setProgress(Math.min(scrollPercentage, 100));
    };
  
    scrollElement.addEventListener('scroll', handleScroll);
    return () => scrollElement.removeEventListener('scroll', handleScroll);
  }, [scrollElement]);

  return { progress };
};

export default useScrollableBehavior;
