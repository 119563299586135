import { useEffect, useMemo, useState } from 'react';
// Translations
import { useTranslation } from 'react-i18next';
// Components
import { FormField } from '../../components/GenericForm/types';

// Store 
import useStore from '../../store/store';

// Utils
import getData from '../../utils/getJsonFile';

/**
 * Hook to fetch form data from a JSON file and return an array of form fields
 * with their respective validation rules.
 * 
 * @returns {FormField[]} An array of form fields with their respective
 * validation rules.
 */
const useFormField = (): FormField[] => { 

 const { i18n } = useTranslation();
 const [formData, setFormData] = useState<any>({});
 const language = useStore((state) => state.language) || i18n.language; 
 
 // Get language from store or i18next 
 const correctData = useMemo(() => formData[language] ?? {}, [formData, language]);
 const apiUrl = process.env.REACT_APP_LANDING_TEXT;

 useEffect(() => { 
  const fetchData = async () => {
     try { 
      const data = await getData(apiUrl); 
      setFormData(data); 
    } catch (error) { console.error('Error fetching form data:', error); } 
  }; 
  fetchData(); 
  // eslint-disable-next-line 
  }, [apiUrl]);

 const isMobile = window.innerWidth <= 600;
 const fields: FormField[] = [
  { id: 'nome', label: 'name', type: 'text', validationRule: (value) => value.trim() !== '', placeholder: 'name' },
  { id: 'email', label: 'email', type: 'email', validationRule: (value) => /\S+@\S+\.\S+/.test(value), placeholder: 'email' },
  { id: 'link', label: isMobile ? 'link' : 'link_name', type: 'text', placeholder: 'Link' },
  { id: 'cognome', label: 'surname', type: 'text', validationRule: (value) => value.trim() !== '', placeholder: 'surname' },
  { id: 'telefono', label: 'phone', type: 'tel', validationRule: (value) => /^\+?[1-9]\d{1,14}$/.test(value), placeholder: 'phone' },
  {
    id: 'select',
    label: 'product_of_interest',
    type: 'select',
    placeholder: 'select_product',
    multiple: true,
    options: correctData && correctData.slides ? correctData.slides.map((slide) => ({ value: slide.title, label: slide.title })) : [],
  }
];

 return fields ;
};

export default useFormField;