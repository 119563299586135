import React, { createContext, useContext, useState, useEffect } from 'react';
import useAuthStore from '../../store/auth';

import { AuthContextType, AuthProviderProps } from './types';

// Create the context with an undefined initial value.This is cause' the context provider will eventually wrap the application and provide the actual value
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Custom hook to use the authentication context. Throws an error if the context is not available, ensuring it's always used within a provider
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

// Define the AuthProvider component. This component wraps around other components and provides them with authentication context
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const { logout } = useAuthStore();

  // Methods to simulate logging in and logging out
  const login = () => setIsAuthenticated(true);
  const handleLogout = () => {
    logout(); 
    setIsAuthenticated(false); 
  }

  useEffect(() => {
    const checkAuthenticationStatus = () => {
      const tokenExists =!!localStorage.getItem('token');
      setIsAuthenticated(tokenExists);
    };

    checkAuthenticationStatus();
    window.addEventListener('storage', checkAuthenticationStatus);

    return () => {
      window.removeEventListener('storage', checkAuthenticationStatus);
    };
  }, []);

 // Return the context provider with the authentication context value. The value includes the authentication state and methods to manipulate it
  return (
    <AuthContext.Provider value={{ isAuthenticated, login, handleLogout }}>
      {children}
    </AuthContext.Provider>
  );
};